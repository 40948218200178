import * as React from "react";
import { Nav, NavItem, NavLink, Alert, Badge } from "reactstrap";
import { Route, Redirect, Switch } from "react-router-dom";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { QueryResult, Query } from "react-apollo";

import { About } from "./About";
import { Team } from "./Team/index";
import { Billing } from "./Billing";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import * as projectQuery from "../../../graphql/queries/project.graphql";
import { ProjectQuery } from "../../../graphql";
import History from "../../History";

export const Edit = (props: EditProps) => {
  const { match } = props;
  const { projectId } = match.params;
  return (
    <div className="project">
      <Query
        query={projectQuery}
        variables={{
          projectId,
        }}
      >
        {(queryResult: QueryResult<ProjectQuery>) => {
          const { loading, error, data } = queryResult;
          if (loading) {
            return (
              <LoadingSpinner />
            );
          }
          if (error || !data) {
            console.error(error);
            return (
              <Alert color="danger">
                <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
                {error && error.message}
              </Alert>
            );
          }
          const { project } = data;
          console.log("project", project);
          return (
            <div>
              <About project={project} />
              <Nav tabs>
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to={`${match.url}/${Tabs.Team}`}
                    activeClassName="active"
                  >
                    Team{" "}
                    <Badge color="primary">{project.members.length + 1}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to={`${match.url}/${Tabs.Problems}`}
                    activeClassName="active"
                  >
                    Problems
                  </NavLink>
                </NavItem>
                {/*
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to={`${match.url}/${Tabs.Billing}`}
                    activeClassName="active"
                  >
                    Billing
                  </NavLink>
                </NavItem>
                */}
              </Nav>
              <div>
                <Switch>
                  <Redirect
                    exact
                    from={`${match.url}/`}
                    to={`${match.url}/${Tabs.Team}`}
                  />
                  <Route
                    path={`${match.url}/${Tabs.Problems}`}
                    render={() => <History projectId={project.id} />}
                  />
                  <Route
                    path={`${match.url}/${Tabs.Team}`}
                    render={() => <Team project={project} />}
                  />
                  <Route
                    path={`${match.url}/${Tabs.Billing}`}
                    component={Billing}
                  />
                </Switch>
              </div>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

interface EditProps {
  match: any;
}

enum Tabs {
  Problems = "problems",
  Team = "team",
  Billing = "billing",
}
