import * as React from "react";
import * as _ from "lodash";

import {
  ProblemQuery_problem_stackTraces_frames,
} from "../../../../graphql";
import SyntaxHighlighter from "../../../../components/SyntaxHighlighter";

export function FrameCode({
  frame,
}: {
  frame: ProblemQuery_problem_stackTraces_frames;
}) {
  const {
    line,
    info,
  } = frame;
  if (info && info.code && line) {
    return (
      <div>
        <br />
        <SyntaxHighlighter
          language="javascript"
          showLineNumbers={true}
          startingLineNumber={Math.max(1, line - 4)}
          wrapLines={true}
          lineProps={lineNumber =>
            lineNumber === line
              ? ({
                  style: { display: "block", cursor: "pointer" },
                } as any)
              : {}
          }
        >
          {info.code
            .split("\n")
            .slice(Math.max(0, line - 5), line + 5)
            .join("\n")}
        </SyntaxHighlighter>
      </div>
    );
  }
  return <div />;
}
