import * as React from "react";
import { UncontrolledTooltip, Row, Col } from "reactstrap";
import * as _ from "lodash";
import {
  ReviewStatus,
  SolutionExternalFields_reviews,
} from "../../../../graphql";
import { ReviewStatusIcon } from "../../../../components/ReviewStatusIcon";

export function StatusIcon({
  solutionId,
  isFullFix,
  positiveReviews,
  reviews,
}: StatusIconProps) {
  const solutionIdSelector = CSS.escape(solutionId);

  /*
  const color = this.positiveReviews.length
  ? this.isFullFix
    ? "success"
    : "info"
  : "default";
  */

  const ribbon = positiveReviews.length ? (
    isFullFix ? (
      <ReviewStatusIcon status={ReviewStatus.FullFix} />
    ) : (
      <ReviewStatusIcon status={ReviewStatus.PartialFix} />
    )
  ) : null;

  return reviews.length > 0 ? (
    <>
      <span id={`reviews-${solutionId}`} className={"review-icon"}>
        {ribbon}
      </span>
      <UncontrolledTooltip
        placement="bottom"
        target={`reviews-${solutionIdSelector}`}
      >
        {reviews.map(review => (
          <Row key={review.id}>
            <Col xs="1">
              <ReviewStatusIcon status={review.status} />
            </Col>
            <Col xs="auto">{review.author.name}</Col>
          </Row>
        ))}
      </UncontrolledTooltip>
    </>
  ) : null;
}

export interface StatusIconProps {
  solutionId: string;
  isFullFix: boolean;
  reviews: SolutionExternalFields_reviews[];
  positiveReviews: SolutionExternalFields_reviews[];
}
