import * as React from "react";

import { BarLoader } from "./BarLoader";

export class LoadingSpinner extends React.Component<{ tall?: boolean }> {
  public render() {
    return (
      <div style={this.props.tall ? { height: "90vh" } : {}}>
        <BarLoader />
      </div>
    );
  }
}
