import * as React from "react";
import { Button } from "reactstrap";
import * as _ from "lodash";

import { Emoji } from "../../../../emoji";

export function Reactions({ reactions }: ReactionsProps) {
  return (
    <div className="pull-right">
      <Reaction iconName="+1">{reactions.thumbsUp}</Reaction>
      <Reaction iconName="-1">{reactions.thumbsDown}</Reaction>
      <Reaction iconName="heart">{reactions.heart}</Reaction>
      <Reaction iconName="tada">{reactions.hooray}</Reaction>
    </div>
  );
}

export function Reaction({
  iconName,
  children,
}: {
  iconName: string;
  children: React.ReactNode;
}) {
  return (
    <Button color={"none"}>
      <Emoji name={iconName} /> {children}
    </Button>
  );
}

export interface ReactionsProps {
  reactions: {
    thumbsUp: number;
    thumbsDown: number;
    heart: number;
    hooray: number;
  };
}
