import * as React from "react";
import * as classnames from "classnames";
import * as ReactTooltip from "react-tooltip";

import { StackTraceKind } from "../graphql";
import * as genericIcon from "../images/stack-trace/kinds/generic.svg";
import * as javaIcon from "../images/stack-trace/kinds/java.svg";
import * as javascriptIcon from "../images/stack-trace/kinds/javascript.svg";
import * as pythonIcon from "../images/stack-trace/kinds/python.svg";
import * as rubyIcon from "../images/stack-trace/kinds/ruby.svg";
import * as typescriptIcon from "../images/stack-trace/kinds/typescript.svg";
import * as webpackIcon from "../images/stack-trace/kinds/webpack.svg";
import * as dockerfileIcon from "../images/stack-trace/kinds/dockerfile.svg";
import * as dockerDaemonIcon from "../images/stack-trace/kinds/docker-daemon.svg";
import * as dockerComposeIcon from "../images/stack-trace/kinds/docker-compose.webp";

export const StackTraceIcon = ({
  kind,
  className,
}: {
  kind: StackTraceKind;
  className?: string;
}) => {
  ReactTooltip.rebuild();
  return (
    <img
      className={classnames("trace-icon", className)}
      src={iconForKind(kind)}
      data-tip={kind}
    />
  );
};

function iconForKind(kind: StackTraceKind) {
  switch (kind) {
    case StackTraceKind.Java:
      return javaIcon;
    case StackTraceKind.Node:
      return javascriptIcon;
    case StackTraceKind.Python:
      return pythonIcon;
    case StackTraceKind.Ruby:
      return rubyIcon;
    case StackTraceKind.TypeScriptCompiler:
      return typescriptIcon;
    case StackTraceKind.Webpack:
      return webpackIcon;
    case StackTraceKind.Dockerfile:
      return dockerfileIcon;
    case StackTraceKind.DockerDaemon:
      return dockerDaemonIcon;
    case StackTraceKind.DockerCompose:
      return dockerComposeIcon;
    default:
      return genericIcon;
  }
}
