import * as React from "react";
import { Alert, ListGroup, Button } from "reactstrap";
import { Query, QueryResult } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import * as projectsQuery from "../../graphql/queries/projects.graphql";
import { ProjectsQuery } from "../../graphql";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Project } from "./Project";

import * as projectsHero from "../../images/projects-hero.png";
import { HeroContainer } from "../../components/HeroContainer";
import { Link } from "react-router-dom";

export class List extends React.Component<ListProps, ListState> {
  public render() {
    return (
      <div>
        <Query query={projectsQuery} variables={{}} pollInterval={10000}>
          {(queryResult: QueryResult<ProjectsQuery>) => {
            const { loading, error, data } = queryResult;
            if (loading) {
              return (
                <LoadingSpinner />
              );
            }
            if (error || !data) {
              console.error(error);
              return (
                <Alert color="danger">
                  <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
                  {error && error.message}
                </Alert>
              );
            }
            const { viewer } = data;
            if (!viewer) {
              console.log('No viewer');
              return (
                <LoadingSpinner />
              );
            }
            const { user } = viewer;
            if (!user) {
              console.log('No user');
              return (
                <LoadingSpinner />
              );
            }
            const { projects } = user;
            if (projects.length === 0) {
              return (
                <HeroContainer
                  title="Create your first project"
                  imageSrc={projectsHero}
                >
                  <p>
                    Create your individual and team projects. Simple invite team
                    members to your projects using their email.
                  </p>
                  <Button
                    tag={Link}
                    color="primary"
                    outline
                    to={"/projects/create"}
                  >
                    Create project
                  </Button>
                </HeroContainer>
              );
            }
            return (
              <div>
                <Button
                  tag={Link} color="primary" outline to="/projects/create"
                  className="pull-right">
                  Create Project
                </Button>
                <h1>Your Projects ({projects.length})</h1>
                <br />
                <ListGroup>
                  {projects.map(project => (
                    <Project key={project.id} project={project} />
                  ))}
                </ListGroup>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export interface ListProps {}

export interface ListState {}
