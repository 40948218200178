import {
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import * as React from "react";
import CardSubtitle from "reactstrap/lib/CardSubtitle";

export function Step(props: StepProps) {
  return (
    <Card>
      <CardBody>
        <CardSubtitle tag="h6" className="text-muted font-weight-light mb-2 mt-0 text-uppercase">Step {props.num}</CardSubtitle>
        <CardTitle tag="h4" className="font-weight-light mb-2">{props.title}</CardTitle>
        <CardText tag="div">{props.children}</CardText>
      </CardBody>
    </Card>
  );
}

export interface StepProps {
  num: number;
  title: string;
  children: React.ReactNode;
}
