import { Container } from "unstated";
import * as auth0 from "auth0-js";

import history from "../history";
import { setUserId } from "../GoogleAnalytics";

declare const MOCK_USER: object | null;
console.log("MOCK_USER", MOCK_USER);

const clientID = "Fcg7Gldlf4FzhaS8aBHyYQVhNtG7HPYd";
const redirectUri =
  location.protocol +
  "//" +
  location.hostname +
  (location.port ? ":" + location.port : "");

export type AuthState = {
  isLoadingUser: boolean;
  userInfo?: auth0.Auth0UserProfile;
};

export class BaseAuthContainer extends Container<AuthState> {
  private auth0 = new auth0.WebAuth({
    domain: "codepass.auth0.com",
    clientID,
    redirectUri,
    responseType: "token id_token",
    scope: "openid profile email",
  });

  public state: AuthState = {
    userInfo: undefined,
    isLoadingUser: false,
  };

  // constructor() {
  //   super();
  //   this.reloadUserInfo().then(() => {
  //     //   this.setState({
  //     //     updatedAt: new Date(),
  //     //   });
  //   });
  // }

  public get isAuthenticated() {
    return Boolean(
      !this.isLoadingUser && !!this.userInfo && this.hasNotExpired()
    );
  }

  public get isLoadingUser() {
    return this.state.isLoadingUser;
  }

  public get userInfo() {
    return this.state.userInfo;
  }

  public login = () => {
    console.log("login");
    this.auth0.authorize({
      state: JSON.stringify({
        pathname: location.pathname,
      })
    });
  };

  public handleAuthentication = (hash: string) => {
    console.log("handleAuthentication");
    return this.parseHash(hash)
      .then(authResult => {
        if (this.isSession(authResult)) {
          this.setSession(authResult);
          return this.loadUserInfo(authResult.accessToken).then(() =>
            history.replace(this.redirectPathNameForSession(authResult))
          );
        } else {
          console.log("missing accessToken and/or idToken", authResult);
        }
      })
      .catch(err => {
        console.log(err);
        return this.reset().then(() => this.goHome());
      });
  };

  private isSession(authResult: auth0.Auth0DecodedHash): authResult is Session {
    return Boolean(authResult && authResult.accessToken && authResult.idToken);
  }

  private parseHash(hash: string): Promise<auth0.Auth0DecodedHash> {
    return new Promise((resolve, reject) => {
      if (hash.trim() === "") {
        return reject(new Error("Hash is empty"));
      }
      this.resetLocationHash();
      this.auth0.parseHash({ hash }, (err, authResult) => {
        if (err) {
          return reject(err);
        }
        if (!authResult) {
          return reject(new Error("Auth result is empty."));
        }
        return resolve(authResult);
      });
    });
  }

  private redirectPathNameForSession(session: Session): string {
    if (!session.state) {
      return '/';
    }
    const { pathname = '/' } = JSON.parse(session.state);
    return pathname;
  }

  private resetLocationHash(): void {
    window.location.hash = "";
  }

  public attemptToReloadUserInfo() {
    console.log("Reload user info?", this);
    if (!this.isLoadingUser && this.accessToken && !this.userInfo) {
      console.log("Reloading user info", this);
      return this.loadUserInfo(this.accessToken);
    }
    this.setAnalyticsUserId();
    return Promise.resolve();
  }

  private setAnalyticsUserId(): void {
    console.log('setAnalyticsUserId', this.state, this);
    if (this.state.userInfo) {
      const userId = this.state.userInfo.sub;
      setUserId(userId);
    } else {
      setUserId(undefined);
    }
  }

  private loadUserInfo(accessToken: string) {
    console.log("loadUserInfo", accessToken);
    this.setState({ isLoadingUser: true });
    return this.getUserInfo(accessToken)
      .then(userInfo => {
        console.log("loadUserInfo userInfo", userInfo);
        return this.setState({ userInfo, isLoadingUser: false })
          .then(() => this.setAnalyticsUserId());
      })
      .catch(error => {
        console.error("loadUserInfo error", error);
        this.reset();
        throw error;
      });
  }

  private setSession(authResult: Session) {
    console.log("setSession", authResult);
    // Set the time that the Access Token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;
  }

  private getUserInfo = (
    accessToken: string
  ): Promise<auth0.Auth0UserProfile> => {
    console.log("getUserInfo");
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(accessToken, function(err, user) {
        console.log("userInfo", err, user);
        if (err) {
          return reject(err);
        }
        return resolve(user);
      });
    });
  };

  public logout = () => {
    this.reset();
    this.goHome();
    this.auth0.logout({
      returnTo: redirectUri,
      clientID,
    });
  };

  private reset() {
    // Clear Access Token and ID Token from local storage
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = null;

    return this.setState({ userInfo: undefined, isLoadingUser: false });
  }

  private goHome() {
    console.log("go home");
    history.replace("/");
  }

  protected hasNotExpired = () => {
    // Check whether the current time is past the
    // Access Token's expiry time
    let expiresAt = JSON.parse(this.expiresAt as any);
    return new Date().getTime() < expiresAt;
  };

  private set accessToken(value: string | null) {
    this.setLocalStorage("access_token", value);
  }
  private get accessToken(): string | null {
    return localStorage.getItem("access_token");
  }

  private set idToken(value: string | null) {
    this.setLocalStorage("id_token", value);
  }
  // private get idToken(): string | null {
  //   return localStorage.getItem("id_token");
  // }

  private set expiresAt(value: string | null) {
    this.setLocalStorage("expires_at", value);
  }
  private get expiresAt(): string | null {
    return localStorage.getItem("expires_at");
  }

  private setLocalStorage(field: string, value: string | null): void {
    if (value === null) {
      localStorage.removeItem(field);
    } else {
      localStorage.setItem(field, value);
    }
  }
}

class MockAuthContainer extends BaseAuthContainer {
  public state: AuthState = {
    userInfo: MOCK_USER as any,
    isLoadingUser: false,
  };

  protected hasNotExpired = () => {
    return true;
  }
}

const AuthContainer = (MOCK_USER ? MockAuthContainer : BaseAuthContainer);
type AuthContainer = BaseAuthContainer;
export { AuthContainer };

interface Session {
  accessToken: string;
  idToken: string;
  expiresIn: number;
  state?: string;
}
