import * as React from "react";

export class BarLoader extends React.Component {
  public render() {
    return (
      <div className="bar-loader-container">
        <div className="Loader">
          <div className="Bar" />
          <div className="Bar" />
          <div className="Bar" />
          <div className="Bar" />
          <div className="Bar" />
          <div className="Bar" />
        </div>
      </div>
    );
  }
}
