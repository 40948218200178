import * as React from "react";
import { Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import { compose, graphql, MutateProps, QueryResult } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import * as createProjectMutation from "../../graphql/mutations/createProject.graphql";
import * as viewerQuery from "../../graphql/queries/viewer.graphql";
import {
  CreateProject,
  CreateProjectVariables,
  ViewerQuery,
} from "../../graphql";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { ApolloQueryResult } from "apollo-client";
import { HeroContainer } from "../../components/HeroContainer";
import * as createProjectHero from "../../images/create-project-hero.svg";
import { RouteComponentProps } from "react-router";

export class CreateInternal extends React.Component<CreateProps, CreateState> {
  state = {
    name: "",
  };

  public render() {
    const { loading, error, data } = this.props;
    if (loading) {
      return (
        <LoadingSpinner />
      );
    }
    if (error || !data) {
      console.error(error);
      return (
        <Alert color="danger">
          <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
          {error && error.message}
        </Alert>
      );
    }
    const { viewer } = data;
    if (!viewer) {
      console.log('No viewer');
      return (
        <LoadingSpinner />
      );
    }
    const { user } = viewer;
    if (!user) {
      console.log('No user');
      return (
        <LoadingSpinner />
      );
    }
    const createProject = this.props.mutate;
    return (
      <div>
        <Form
          onSubmit={e => {
            e.preventDefault();
            createProject({
              variables: { name: this.state.name, ownerId: user.id },
            })
              .then((response: ApolloQueryResult<CreateProject>) => {
                this.setState(prevState => ({
                  ...prevState,
                  name: "",
                }));
                const projectId = response.data.createProject.id;
                return this.props.onCreateProject(projectId);
              })
              .catch(error => console.error(error));
          }}
        >
          <FormGroup>
            <Label for="project-name" sm={2} hidden>
              Name
            </Label>
            <Input
              type="text"
              name="project-name"
              id="project-name"
              placeholder="Enter project name"
              required
              value={this.state.name}
              onChange={this.onChangeName}
              valid={this.isValid}
            />
            <Button color="primary" disabled={!this.isValid}>
              Create Project
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }

  private get isValid(): boolean {
    return this.state.name.length > 2;
  }

  private onChangeName = (event: any) => {
    const { value } = event.target;
    this.setState(prevState => ({
      ...prevState,
      name: value,
    }));
  };
}

type CreateInternalProps = RouteComponentProps<{}, {}> & {
  onCreateProject: (projectId: string) => void;
};

type CreateProps = CreateInternalProps &
  QueryResult<ViewerQuery, {}> &
  MutateProps<any, CreateProjectVariables>;

interface CreateState {
  name: string;
}

export const Create: React.SFC<CreateInternalProps> = compose(
  graphql(createProjectMutation, {
    options: {
      refetchQueries: ["ProjectsQuery"],
    },
  }),
  graphql(viewerQuery)
)(CreateInternal);

export function CreateProjectPage(props: CreateInternalProps) {
  const goToProject = (projectId: string) => {
    return props.history.push(`/projects/edit/${projectId}`);
  };
  return (
    <HeroContainer title="Create a new project?" imageSrc={createProjectHero}>
      <p>After you create a project you will be able to add team members.</p>
      <Create {...props} onCreateProject={goToProject} />
    </HeroContainer>
  );
}
