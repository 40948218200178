import * as React from "react";
import {
  Router,
  Route,
  RouteComponentProps,
} from "react-router-dom";
import { hot } from "react-hot-loader";
import { ApolloProvider } from "react-apollo";
import { Provider, Subscribe } from "unstated";
import * as ReactTooltip from "react-tooltip";

import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";

import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

import * as introspectionQueryResultData from './graphql/fragmentTypes.json';
import history from "./history";
import { AuthContainer } from "./Auth/AuthContainer";
import { UnauthenticatedLandingPage } from "./Auth/UnauthenticatedLandingPage";
import Routes from "./routes";

import 'react-calendar-heatmap/dist/styles.css';
import 'react-diff-view/index.css';
import { LoadingSpinner } from "./components/LoadingSpinner";
require("font-awesome/css/font-awesome.css");
require("inter-ui");
require("./styles/main.scss");

declare const GRAPHQL_API_URL: string;
const API_URL: string = GRAPHQL_API_URL;
console.log("API_URL", API_URL);

const httpLink = createHttpLink({
  uri: API_URL,
  credentials: "omit",
  fetchOptions: {
    mode: "cors",
    redirect: "follow", // manual, *follow, error
    referrer: "no-referrer", // no-referrer, *client
  } as any,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("access_token");
  if (!token) {
    return { headers };
  }
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    fragmentMatcher,
  }),
});

const HandleAuthentication = (nextState: RouteComponentProps<any>) => {
  return (
    <Subscribe to={[AuthContainer]}>
      {(auth: AuthContainer) => {
        const hash = nextState.location.hash;
        if (isAuth0Callback(hash)) {
          if (auth.isLoadingUser) {
            console.log("Is already loading user");
          } else {
            auth.handleAuthentication(hash);
          }
          return <div />;
        }
        if (auth.isAuthenticated) {
          return (
            <div>
              <Routes />
            </div>
          );
        }
        if (auth.isLoadingUser) {
          return (
            <LoadingSpinner tall />
          );
        }
        auth.attemptToReloadUserInfo();
        return <UnauthenticatedLandingPage auth={auth} />;
      }}
    </Subscribe>
  );
};

function isAuth0Callback(hash: string): boolean {
  return /access_token|id_token|error/.test(hash);
}

const App = () => (
  <Provider>
    <ApolloProvider client={client}>
      <Router history={history}>
        <div>
          <Route path="/" component={HandleAuthentication} />
          <ReactTooltip />
        </div>
      </Router>
    </ApolloProvider>
  </Provider>
);

export default hot(module)(App);
