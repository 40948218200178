import * as React from "react";
import {
  Badge,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { compose, graphql, MutateProps } from "react-apollo";
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import * as createReviewMutation from "../../../../graphql/mutations/createReview.graphql";
import * as problemSolutionsQuery from "../../../../graphql/queries/problemSolutions.graphql";
import {
  SolutionExternalFields as SolutionFields,
  ReviewStatus,
  SolutionExternalFields_reviews as Reviews,
  CreateReviewVariables,
  ProblemSolutionsQuery,
  ProblemSolutionsQuery_problem as Problem,
  ProblemQuery_problem_similar,
} from "../../../../graphql";

export class SolutionReviewStats extends React.Component<
  SolutionReviewStatsProps,
  {}
> {
  public render() {
    return (
      <React.Fragment>
        <span>Did this solution work?{"  "}</span>
        <UncontrolledButtonDropdown>
          <DropdownToggle
            caret
            color="success"
            outline
            // outline={!this.isFullFix}
          >
            <FontAwesomeIcon icon={faCheck} />
            {"  "}
            Yes{" "}
            <Badge color="success">
              {this.positiveReviews.length}
            </Badge>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => this.setReviewStatus(ReviewStatus.FullFix)}
            >
              <Badge color="success">
                {this.reviewsForStatus(ReviewStatus.FullFix).length}
              </Badge>{" "}
              <span>Completely fixes my problem</span>
            </DropdownItem>
            <DropdownItem
              onClick={() => this.setReviewStatus(ReviewStatus.PartialFix)}
            >
              <Badge color="info">
                {this.reviewsForStatus(ReviewStatus.PartialFix).length}
              </Badge>{" "}
              <span>Partially fixes my problem</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>{" "}
        <UncontrolledButtonDropdown>
          <DropdownToggle caret color="danger" outline>
            <FontAwesomeIcon icon={faTimes} />
            {"  "}
            No <Badge color="danger">{this.negativeReviews.length}</Badge>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => this.setReviewStatus(ReviewStatus.NotAFix)}
            >
              <Badge color="danger">
                {this.reviewsForStatus(ReviewStatus.NotAFix).length}
              </Badge>{" "}
              <span>Did not fix my problem</span>
            </DropdownItem>
            <DropdownItem
              onClick={() => this.setReviewStatus(ReviewStatus.Irrelevant)}
            >
              <Badge color="warning">
                {this.reviewsForStatus(ReviewStatus.Irrelevant).length}
              </Badge>{" "}
              <span>Irrelevant to my problem</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </React.Fragment>
    );
  }

  private get positiveReviews(): Reviews[] {
    return [
      ...this.reviewsForStatus(ReviewStatus.FullFix),
      ...this.reviewsForStatus(ReviewStatus.PartialFix),
    ];
  }

  private get negativeReviews(): Reviews[] {
    return [
      ...this.reviewsForStatus(ReviewStatus.NotAFix),
      ...this.reviewsForStatus(ReviewStatus.Irrelevant),
    ];
  }

  private reviewsForStatus(status: ReviewStatus): Reviews[] {
    return this.directReviewsForStatus(status).concat(
      this.similarReviewsForStatus(status)
    );
  }

  private directReviewsForStatus(status: ReviewStatus): Reviews[] {
    return this.reviews.filter(review => review.status === status);
  }

  private similarReviewsForStatus(status: ReviewStatus): Reviews[] {
    return _.flatten(
      this.props.similarProblems.map(problem => problem.reviews)
    ).filter(
      review =>
        review.solutionId === this.solution.id && review.status === status
    );
  }

  private get reviews() {
    return this.solution.reviews;
  }

  private get solution() {
    return this.props.solution;
  }

  private setReviewStatus = (reviewStatus: ReviewStatus) => {
    console.log("setReviewStatus", reviewStatus, this);
    return this.createReview({
      status: reviewStatus,
      solutionId: this.solution.id,
      solutionSource: this.solution.source,
      problemId: this.props.problem.id,
    });
  };

  private createReview = (variables: CreateReviewVariables) => {
    const createReview = this.props.mutate;
    console.log("createReview", variables.solutionId, variables.status);
    return createReview({
      variables,
      optimisticResponse: {
        __typename: "Mutation",
        createReview: {
          ...variables,
          __typename: "Review",
          id: `optimistic-${variables.problemId}-${variables.solutionId}`,
          createdAt: new Date(),
          updatedAt: new Date(),
          author: {
            __typename: "User",
            id: null,
            name: "You",
          },
          problem: {
            __typename: "Problem",
            id: variables.problemId,
          },
          project: {
            __typename: "Project",
            id: null,
          },
        },
      },
      update: (proxy, mutationResult: any) => {
        // console.log('mutationResult', mutationResult);
        const {
          data: { createReview },
        } = mutationResult;
        console.log(
          "createReview",
          createReview.solutionId,
          createReview.status,
          createReview.id
        );
        // Read the data from our cache for this query.
        const rawData = proxy.readQuery<ProblemSolutionsQuery>({
          query: problemSolutionsQuery,
          variables: {
            projectId: createReview.problem.id,
          },
        });
        if (!rawData) {
          return;
        }
        const data = _.cloneDeep(rawData);
        // Add our comment from the mutation to the end.
        const solutions = data.problem.solutions;
        if (!solutions) {
          return;
        }
        const solution = solutions.find(
          solution => solution.id === createReview.solutionId
        );
        if (!solution) {
          return;
        }
        // const review = solution.reviews.find(
        //   review =>
        //     review.author.id === createReview.author.id &&
        //     review.problem.id === createReview.problem.id
        // );
        const reviewIndex = solution.reviews.findIndex(
          review => review.id === createReview.id
        );
        if (reviewIndex !== -1) {
          solution.reviews[reviewIndex].status = createReview.status;
          solution.reviews[reviewIndex] = createReview;
          // solution.reviews.push(createReview);
        } else {
          solution.reviews.push(createReview);
        }
        // console.log("solution.reviews", JSON.stringify(solution.reviews, null, 2));
        // Write our data back to the cache.
        proxy.writeQuery({
          query: problemSolutionsQuery,
          variables: {
            projectId: createReview.problem.id,
          },
          data,
        });
      },
    });
  };
}

export default compose(graphql(createReviewMutation))(SolutionReviewStats);

export type SolutionReviewStatsProps = {
  problem: Problem;
  solution: SolutionFields;
  similarProblems: ProblemQuery_problem_similar[];
} & MutateProps<any, CreateReviewVariables>;
