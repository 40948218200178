import * as React from "react";
import { Alert, ListGroup } from "reactstrap";
import { Query, QueryResult } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import * as problemDependenciesQuery from "../../../../graphql/queries/problemDependencies.graphql";
import {
  ProblemDependenciesQuery,
  ProblemDependenciesQueryVariables,
} from "../../../../graphql";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";

import { SubDependency } from "./SubDependency";

export class ProblemDependencies extends React.Component<
  ProblemDependenciesProps,
  {}
> {
  public render() {
    return (
      <div>
        <Query query={problemDependenciesQuery} variables={this.queryVariables}>
          {(queryResult: QueryResult<ProblemDependenciesQuery>) => {
            const { loading, error, data } = queryResult;
            if (loading) {
              return (
                <LoadingSpinner />
              );
            }
            if (error || !data) {
              console.error(error);
              return (
                <Alert color="danger">
                  <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
                  {error && error.message}
                </Alert>
              );
            }
            const { problem } = data;
            const { dependenciesList } = problem;
            return (
              <ListGroup>
                {dependenciesList.map((dep, index) => (
                  <SubDependency
                    key={`${dep.name}@${dep.version}`}
                    problemId={this.props.problemId}
                    parents={[]}
                    dependency={dep}
                  />
                ))}
              </ListGroup>
            );
          }}
        </Query>
      </div>
    );
  }

  private get queryVariables(): ProblemDependenciesQueryVariables {
    return {
      projectId: this.props.problemId,
      parents: [],
    };
  }
}

export interface ProblemDependenciesProps {
  problemId: string;
}
