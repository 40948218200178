import * as React from "react";
import * as _ from "lodash";
import ReactHtmlParser from "react-html-parser";
import { Alert } from "reactstrap";

import { ProblemQuery_problem_stackTraces_frames } from "../../../../graphql";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";

export class FrameDocs extends React.PureComponent<
  FrameDocsProps,
  FrameDocsState
> {
  public state: FrameDocsState = {
    loading: false,
  };

  public componentDidMount() {
    this.loadDocs();
  }

  public render() {
    if (this.state.loading) {
      <LoadingSpinner />
    }
    const { html } = this.state;
    if (html) {
      return (
        <Alert color="secondary" className="frame-docs-container">{this.renderHTML(html)}</Alert>
      );
    }
    return <div />;
  }

  private async loadDocs() {
    const { callee } = this.frame;
    if (!callee || !this.shouldHaveDocs) {
      return;
    }
    this.setState(prevState => ({
      ...prevState,
      loading: true,
    }));
    return fetch(this.docsUrl(callee))
      .then(async res => {
        const html = await res.text();
        this.setState(prevState => ({
          ...prevState,
          loading: false,
          html,
        }));
      })
      .catch(error => {
        this.setState(prevState => ({
          ...prevState,
          loading: false,
        }));
      });
  }

  private get shouldHaveDocs() {
    const { callee, package: pkg, info, fileName, filePath } = this.frame;
    return callee && !(pkg || info || fileName || filePath);
  }

  private docsUrl(callee: string) {
    const urlPath = callee
      .toLowerCase()
      .replace('new ', '')
      .split(".")
      .join("/")
      ;
    return `https://docs.devdocs.io/javascript/global_objects/${urlPath}.html`;
  }

  private get frame() {
    return this.props.frame;
  }

  private renderHTML(html: string) {
    return ReactHtmlParser(html, {
      transform: this.transform,
    });
  }

  private transform(node: any) {
    if (node.type === "tag") {
      if (node.name === "a") {
        node.attribs.target = "_blank";
      }
    }
    return;
  }
}

interface FrameDocsProps {
  frame: ProblemQuery_problem_stackTraces_frames;
}

interface FrameDocsState {
  loading: boolean;
  html?: string;
}
