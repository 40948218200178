import * as React from "react";
import TimeAgo from "react-timeago";
import * as moment from "moment";
import * as ReactTooltip from "react-tooltip";

export function DateTime({ date }: { date: Date | string }) {
  ReactTooltip.rebuild();
  return (
    <span
      className="time-ago"
      data-tip={moment(date).format("dddd, MMMM Do, YYYY [at] hh:mm:ss A")}
    >
      <TimeAgo date={date} />
    </span>
  );
}
