import { Card, CardHeader, CardBody, Button } from "reactstrap";
import * as React from "react";
import { Link } from "react-router-dom";
import * as ReactTooltip from "react-tooltip";
import * as moment from "moment";
import TimeAgo from "react-timeago";

import {
  ProblemsQuery_viewer_user_problems,
  ReviewStatus,
} from "../../graphql";
import { StackTraces } from "../Search/Problem/StackTraces";
import { parseGraphQLDate } from "../../utils/parseGraphQLDate";
import { ReviewStatusIcon } from "../../components/ReviewStatusIcon";

export const Problem = ({
  problem,
}: {
  problem: ProblemsQuery_viewer_user_problems;
}) => {
  const stackTraces = problem.stackTraces.map(stackTrace => ({
    ...stackTrace,
    frames: stackTrace.frames.map(frame => ({
      ...frame,
      info: null,
    })),
  }));
  const fullFixes = problem.reviews.filter(
    review => review.status === ReviewStatus.FullFix
  );
  const partialFixes = problem.reviews.filter(
    review => review.status === ReviewStatus.PartialFix
  );
  const hasFix = fullFixes.length > 0;
  const hasPartialFix = partialFixes.length > 0;
  // const color = hasFix ? "success" : hasPartialFix ? "info" : "";
  const ribbon = hasFix ? (
    <span data-tip="Completely Fixed">
      <ReviewStatusIcon status={ReviewStatus.FullFix} />
    </span>
  ) : hasPartialFix ? (
    <span data-tip="Partially Fixed">
      <ReviewStatusIcon status={ReviewStatus.PartialFix} />
    </span>
  ) : null;
  ReactTooltip.rebuild();
  const createdAt = parseGraphQLDate(problem.createdAt);
  return (
    <Card key={problem.id} className="problem-card">
      <CardHeader tag="div">
        {ribbon && <>{ribbon} </>}

        <span>
          <span className="project-name">
            <Link to={`/projects/edit/${problem.project.id}`}>
              {problem.project.name}
            </Link>
          </span>{" "}
          from{" "}
          <span
            className="time-ago"
            data-tip={moment(createdAt).format(
              "dddd, MMMM Do, YYYY [at] hh:mm:ss A"
            )}
          >
            <TimeAgo date={createdAt} />
          </span>{" "}
          <span className="author-name">
            by <span>{problem.user.name}</span>
          </span>
        </span>
        <Button
          color="success"
          outline
          tag={Link}
          to={`/search/${problem.id}`}
          className="pull-right"
        >
          View Solutions
        </Button>
      </CardHeader>
      <CardBody>
        <StackTraces stackTraces={stackTraces} />
      </CardBody>
    </Card>
  );
};
