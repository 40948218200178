import * as React from "react";
import { TabContent as TabContentBase, TabContentProps } from "reactstrap";

import { TabContext } from "./TabContext";

export const TabContent = (props: TabContentProps) => {
  return (
    <TabContext.Consumer>
      {({ activeTab }) => (
        <TabContentBase activeTab={activeTab} {...props} />
      )}
    </TabContext.Consumer>
  );
};
