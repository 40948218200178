import * as React from "react";
import { Alert } from "reactstrap";
import ICalendarHeatmap from "react-calendar-heatmap";
import * as moment from "moment";
const CalendarHeatmap: typeof ICalendarHeatmap = require("react-calendar-heatmap");
import * as classnames from "classnames";

import { Query, QueryResult } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import * as ReactTooltip from "react-tooltip";

import * as historyHeatmapByDayQuery from "../graphql/queries/historyHeatmapByDay.graphql";
import { HistoryHeatmapByDayQuery, HistoryHeatmapByDayQuery_historyHeatmap_byDay } from "../graphql";
import { LoadingSpinner } from "../components/LoadingSpinner";

import "react-calendar-heatmap/dist/styles.css";

export const ProblemsHeatmapContainer: React.SFC<
  ProblemsHeatmapContainerProps
> = function(props) {
  return (
    <Query
      query={historyHeatmapByDayQuery}
      variables={{
        userId: props.userId,
        projectId: props.projectId,
      }}
    >
      {(queryResult: QueryResult<HistoryHeatmapByDayQuery>) => {
        const { loading, error, data } = queryResult;
        if (loading) {
          return (
            <LoadingSpinner />
          );
        }
        if (error || !data) {
          console.error(error);
          return (
            <Alert color="danger">
              <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
              {error && error.message}
            </Alert>
          );
        }

        return (
          <ProblemsHeatmap
            data={data.historyHeatmap.byDay}
            selected={props.selected}
            onClick={props.onClick}
          />
        );
      }}
    </Query>
  );
};

export class ProblemsHeatmap extends React.PureComponent<ProblemsHeatmapProps> {
  private numDays = 365;

  public render() {
    const { props } = this;
    return (
      <div
        className={
          classnames('problems-heatmap', {
            'has-selection': props.selected,
          })
        }
      >
        <CalendarHeatmap
          startDate={this.startDate}
          endDate={this.today}
          values={this.values}
          classForValue={(value: ProblemHeatDay) => {
            if (!value) {
              return "heatmap-day color-empty";
            }
            return `heatmap-day color-heat-${Math.round(
              Math.min(10, value.count)
            )}`;
          }}
          tooltipDataAttrs={(value: ProblemHeatDay) => {
            if (!value) {
              return {
                "data-tip": `No problems found.`,
              };
            }
            if (this.isActiveDay(value)) {
              return {
                "data-tip": `Click again to clear filter and show recent.`,
              };
            }
            return {
              "data-tip": `Click to view ${value.count} problem${value.count === 1 ? '' : 's'} for ${moment(value.date).format(
                "dddd, MMMM Do, YYYY"
              )}.`,
            };
          }}
          showWeekdayLabels={true}
          onClick={props.onClick}
          transformDayElement={(element, value: ProblemHeatDay) => {
            const { selected } = props;
            if (!value || !selected) {
              return element;
            }
            const { className } = element.props;
            return React.cloneElement(element, {
              className: this.isActiveDay(value) ? `${className} active` : className,
            });
          }}
        />
      </div>
    );
  }

  private isActiveDay(value: ProblemHeatDay): boolean {
    const { selected } = this.props;
    if (!value || !selected) {
      return false;
    }
    return value.date.getTime() === selected.date.getTime();
  }

  public componentDidMount() {
    ReactTooltip.rebuild();
  }

  public componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  private get values(): ProblemHeatDay[] {
    const heatmapValues: ProblemHeatDay[] = this.props.data.map(
      item => ({
        count: item.count,
        date: moment([item.year, item.month - 1, item.day]).toDate(),
      })
    );
    // console.log("heatmapValues", heatmapValues);
    const values = this.fillValues(heatmapValues);
    // console.log("values", values);
    return values;
  }

  private fillValues(sparseValues: ProblemHeatDay[]): ProblemHeatDay[] {
    const today = this.today;
    const valueMap: { [key: number]: number } = sparseValues.reduce((valueMap, val: ProblemHeatDay) => ({
      ...valueMap,
      [val.date.getTime()]: val.count,
    }), {});
    const values: ProblemHeatDay[] = getRange(this.numDays).map((index: number): ProblemHeatDay => {
      const currDate = shiftDate(today, -index);
      const count = valueMap[currDate.getTime()];
      return ({
        date: currDate,
        // date: today,
        count: isNaN(count) ? 0 : count,
      })
    });
    return values;
  }

  private get startDate(): Date {
    return shiftDate(this.today, -this.numDays);
  }

  private get today(): Date {
    const today = new Date();
    const todayRounded = moment([today.getFullYear(), today.getMonth(), today.getDate()]).toDate();
    return todayRounded;
  }

}

interface ProblemsHeatmapContainerProps {
  userId?: string;
  projectId?: string;
  selected?: ProblemHeatDay;
  onClick?(datum: ProblemHeatDay): void;
}

interface ProblemsHeatmapProps {
  // data: ProblemHeatDay[];
  data: HistoryHeatmapByDayQuery_historyHeatmap_byDay[];
  selected?: ProblemHeatDay;
  onClick?(datum: ProblemHeatDay): void;
}

export interface ProblemHeatDay {
  date: Date;
  count: number;
}

function shiftDate(date: Date, numDays: number) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

function getRange(count: number): number[] {
  return Array.from({ length: count }, (_, i) => i);
}

/*
const randomValues = getRange(numDays).map((index: number): ProblemHeatDay => {
  const problems = getRandomInt(0, 10);
  const solved = getRandomInt(0, problems);
  return {
    date: shiftDate(today, -index),
    count: problems,
    problems,
    solved,
  };
});

function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
*/
