import * as React from "react";

import { HeroContainer } from "../components/HeroContainer";
import * as analyticsHero from "../images/analytics-hero.svg";

export default function Analytics() {
  return (
    <div>
      <HeroContainer
        title="Analytics for your project"
        imageSrc={analyticsHero}
      >
        <p>
          See how many minuetes you save per day, how many error searches you
          do, and various productivity metrics and analytics.
        </p>
      </HeroContainer>
    </div>
  );
}
