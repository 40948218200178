import * as React from "react";

import {
    ProblemCommitsQuery_problem_commits
} from "../../../graphql";
import { Commit } from "./Commit";

export class Commits extends React.Component<CommitsProps, {}> {
  public render() {
    return this.props.commits.map((commit) => {
        return (
            <Commit key={commit.id} commit={commit} />
        )
    });
  }
}

export interface CommitsProps {
  commits: ProblemCommitsQuery_problem_commits[];
}
