import * as React from "react";
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  TabPane,
  Nav,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { Query, QueryResult } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faDownload,
  faFileAlt,
  faFireExtinguisher,
  faCode,
  faNetworkWired,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import * as problemQuery from "../../../graphql/queries/problem.graphql";
import * as problemSolutionsQuery from "../../../graphql/queries/problemSolutions.graphql";
import * as problemCommitsQuery from "../../../graphql/queries/problemCommits.graphql";
import {
  ProblemQuery,
  ProblemSolutionsQuery,
  ProblemCommitsQuery,
  ProblemQuery_problem_similar,
} from "../../../graphql";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { Solutions } from "./Solutions";
import { StackTraces } from "./StackTraces";
import { DownloadTextButton } from "../../../components/DownloadTextButton";
import { parseGraphQLDate } from "../../../utils/parseGraphQLDate";
import { Commits } from "./Commits";
import { Problem as HistoryProblem } from "./../../History/Problem";
import { SolutionInput } from "./SolutionInput";
import { TabContainer, TabContent, TabNavItem } from "../../../components/Tabs";
import { ProblemDependencies } from "./Dependencies";
import { SolutionsLoader } from "../../../components/SolutionsLoader";
import { DateTime } from "../../../components/DateTime";
import { ProblemEnvironment } from "./Environment";

export class Problem extends React.Component<ResultsProps, ResultsState> {
  // private solutionsPollInterval: number = 5000;

  public render() {
    return (
      <div>
        <Query
          query={problemQuery}
          variables={{
            projectId: this.props.problemId,
          }}
        >
          {(queryResult: QueryResult<ProblemQuery>) => {
            const { loading, error, data } = queryResult;
            if (loading) {
              return (
                <LoadingSpinner tall />
              );
            }
            if (error || !data) {
              console.error(error);
              return (
                <Alert color="danger">
                  <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
                  {error && error.message}
                </Alert>
              );
            }
            const { problem } = data;
            const date = parseGraphQLDate(problem.createdAt);
            return (
              <div>
                <div>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={`/projects/edit/${problem.project.id}`}>
                        {problem.project.name}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      {problem.user.name}
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <DateTime date={date} />
                    </BreadcrumbItem>
                  </Breadcrumb>
                </div>
                <Row>
                  <Col xs="6">
                    <SolutionInput problem={problem} />
                    <Card>
                      <CardHeader>
                        <h3>
                          Stack Trace{problem.stackTraces.length === 1 ? '' : 's'}
                        </h3>
                      </CardHeader>
                      <CardBody>
                        <StackTraces stackTraces={problem.stackTraces} />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="6">

                  <TabContainer defaultTab={Tabs.Solutions}>

                    <Nav pills>
                      <ProblemTabNavItem
                        tab={Tabs.Solutions}
                        icon={faFireExtinguisher}
                      >
                        Solutions
                      </ProblemTabNavItem>

                      <ProblemTabNavItem
                        tab={Tabs.Similar}
                        icon={faCopy}
                      >
                        Similar Problems
                        {/* <Badge color="primary">{problem.similar.length}</Badge> */}
                      </ProblemTabNavItem>

                      <ProblemTabNavItem
                        tab={Tabs.Commits}
                        icon={faCode}
                      >
                        Commits
                      </ProblemTabNavItem>

                      <ProblemTabNavItem
                        tab={Tabs.Environment}
                        icon={faNetworkWired}
                      >
                        Environment
                      </ProblemTabNavItem>

                      <ProblemTabNavItem
                        tab={Tabs.Dependencies}
                        icon={faNetworkWired}
                      >
                        Dependencies
                      </ProblemTabNavItem>

                      <ProblemTabNavItem
                        tab={Tabs.RawLogs}
                        icon={faFileAlt}
                      >
                        Raw Logs
                      </ProblemTabNavItem>
                    </Nav>
                    <TabContent className="problem-tab-content">
                      {/* <ProblemTabPane tabId={Tabs.Details}>
                        <StackTraces stackTraces={problem.stackTraces} />
                      </ProblemTabPane> */}
                      <ProblemTabPane tabId={Tabs.Similar}>
                        {problem.similar.length === 0 ? (
                          <div>No similar problems found</div>
                        ) : (
                          problem.similar.map(similarProblem => (
                            <HistoryProblem
                              key={similarProblem.id}
                              problem={similarProblem}
                            />
                          ))
                        )}
                      </ProblemTabPane>
                      <ProblemTabPane tabId={Tabs.Solutions}>
                        {this.renderSolutions(problem.similar)}
                      </ProblemTabPane>
                      <ProblemTabPane tabId={Tabs.Commits}>
                        {this.renderCommits()}
                      </ProblemTabPane>
                      <ProblemTabPane tabId={Tabs.Environment}>
                        <ProblemEnvironment environment={problem.environment} />
                      </ProblemTabPane>
                      <ProblemTabPane tabId={Tabs.Dependencies}>
                        <ProblemDependencies problemId={this.props.problemId} />
                      </ProblemTabPane>
                      <ProblemTabPane tabId={Tabs.RawLogs}>
                        <DownloadTextButton
                          fileName={`codepass-${problem.id}.log`}
                          text={problem.logs || ""}
                        >
                          <FontAwesomeIcon icon={faDownload} /> Download Logs
                        </DownloadTextButton>
                        <pre>{problem.logs}</pre>
                      </ProblemTabPane>
                    </TabContent>

                    </TabContainer>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }

  public renderSolutions(similarProblems: ProblemQuery_problem_similar[]) {
    return (
      <div>
        <Query
          query={problemSolutionsQuery}
          variables={{
            projectId: this.props.problemId,
          }}
          // pollInterval={this.solutionsPollInterval}
        >
          {(queryResult: QueryResult<ProblemSolutionsQuery>) => {
            const { loading, error, data, stopPolling } = queryResult;
            if (loading) {
              return (
                <SolutionsLoader />
              );
            }
            if (error || !data) {
              if (error) {
                console.error(error);
                stopPolling();
              }
              return (
                <Alert color="danger">
                  <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
                  {error && error.message}
                </Alert>
              );
            }
            const { problem } = data;
            const { solutions } = problem;
            if (!solutions) {
              return (
                <LoadingSpinner />
              );
            }
            stopPolling();
            return <Solutions
              problem={problem}
              solutions={solutions}
              similarProblems={similarProblems}
            />;
          }}
        </Query>
      </div>
    );
  }

  public renderCommits() {
    return (
      <div>
        <Query
          query={problemCommitsQuery}
          variables={{
            projectId: this.props.problemId,
          }}
        >
          {(queryResult: QueryResult<ProblemCommitsQuery>) => {
            const { loading, error, data } = queryResult;
            if (loading) {
              return (
                <LoadingSpinner />
              );
            }
            if (error || !data) {
              if (error) {
                console.error(error);
              }
              return (
                <Alert color="danger">
                  <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
                  {error && error.message}
                </Alert>
              );
            }
            const { problem } = data;
            const { commits } = problem;
            if (!commits) {
              return (
                <LoadingSpinner />
              );
            }
            if (!commits.length) {
              return <div>No commits found</div>;
            }
            return <Commits commits={commits} />;
          }}
        </Query>
      </div>
    );
  }
}

export interface ResultsProps {
  problemId: string;
}

export interface ResultsState {
}

enum Tabs {
  Similar = "similar",
  Solutions = "solutions",
  Commits = "commits",
  Environment = "environment",
  Dependencies = "dependencies",
  RawLogs = "logs",
}

const ProblemTabNavItem = ({
  tab,
  children,
}: {
  tab: Tabs;
  icon: IconProp;
  children: React.ReactNode;
}) => {
  return (
    <TabNavItem tab={tab}>
      {/* <FontAwesomeIcon icon={icon} />  */}
      {children}
    </TabNavItem>
  );
};

const ProblemTabPane = ({
  tabId,
  children,
}: {
  tabId: Tabs;
  children: React.ReactNode;
}) => {
  return <TabPane tabId={tabId}>{children}</TabPane>;
};
