import * as React from "react";
import { Row, Col } from "reactstrap";

export const HeroContainer = (props: HeroContainerProps) => {
  return (
    <div className="hero-container">
      <div className="image-container pull-right">
        <img src={props.imageSrc} />
      </div>
      <div>
        <Row>
          <Col
            xl={5}
            lg={{ size: 5, offset: 1 }}
            md={{ size: 4, offset: 1 }}
            sm={4}
            xs={3}
          >
            <h2>{props.title}</h2>
            <div>{props.children}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

interface HeroContainerProps {
  title: string;
  imageSrc: any;
  children: React.ReactNode;
}
