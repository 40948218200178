import * as React from "react";
import TimeAgo from "react-timeago";

import { ProjectQuery_project as Project } from "../../../graphql";
import { parseGraphQLDate } from "../../../utils/parseGraphQLDate";

export const About = (props: AboutProps) => {
  const { project } = props;
  return (
    <div>
      <h1>
        {project.name}{" "}
        <small>
          (<TimeAgo date={parseGraphQLDate(project.createdAt)} />)
        </small>
      </h1>
    </div>
  );
};

interface AboutProps {
  project: Project;
}
