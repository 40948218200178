import * as React from "react";
import {
  Alert,
  DropdownMenu,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { compose, graphql, QueryResult } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import * as projectsQuery from "../../../graphql/queries/projects.graphql";
import {
  ProjectsQuery,
  ProjectsQuery_viewer_user_projects as Project,
} from "../../../graphql";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

export class SelectProjectDropdownInternal extends React.Component<
  SelectProjectDropdownProps,
  SelectProjectDropdownState
> {
  state = {
    name: "",
  };

  public render() {
    const { loading, error, data, projectIds = [] } = this.props;
    if (loading) {
      return <LoadingSpinner />;
    }
    if (error || !data) {
      console.error(error);
      return (
        <Alert color="danger">
          <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
          {error && error.message}
        </Alert>
      );
    }
    const { viewer } = data;
    if (!viewer) {
      console.log("No viewer");
      return <LoadingSpinner />;
    }
    const { user } = viewer;
    if (!user) {
      console.log("No user");
      return <LoadingSpinner />;
    }
    const { projects } = user;
    const selectedProjects = projects.filter(project =>
      projectIds.includes(project.id)
    );
    return (
      <UncontrolledButtonDropdown addonType="append">
        <DropdownToggle caret outline color="secondary">
          {selectedProjects.length === 0 ? (
            "Filter by project"
          ) : (
            <span>
              {selectedProjects.map(project => project.name).join(" or ")}
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu>
          {projects.map((project: Project) => {
            const active = projectIds.includes(project.id);
            const onClick = () => {
              console.log("select project", project);
              this.props.toggleProject(project);
            };
            return (
              <ProjectFilter
                key={project.id}
                onClick={onClick}
                project={project}
                active={active}
              />
            );
          })}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  }
}

const ProjectFilter = ({
  project,
  active,
  onClick,
}: {
  project: Project;
  active: boolean;
  onClick(event: any): void;
}) => (
  <DropdownItem active={active} onClick={onClick}>
    {project.name}
  </DropdownItem>
);

type SelectProjectDropdownInternalProps = {
  projectIds?: string[];
  toggleProject(project: Project): void;
};

type SelectProjectDropdownProps = QueryResult<ProjectsQuery, {}> &
  SelectProjectDropdownInternalProps;

interface SelectProjectDropdownState {}

export const SelectProjectDropdown: React.SFC<
  SelectProjectDropdownInternalProps
> = compose(graphql(projectsQuery))(SelectProjectDropdownInternal);
