import * as React from "react";
import * as _ from "lodash";

import Solution from "./Solution";
import {
  ProblemSolutionsQuery_problem_solutions as ProblemSolution,
  ProblemSolutionsQuery_problem as Problem,
  ProblemQuery_problem_similar,
  ReviewStatus,
} from "../../../graphql";

export class Solutions extends React.Component<SolutionsProps, SolutionsState> {
  public render() {
    const { solutions } = this.props;
    if (solutions.length === 0) {
      return <div>No solutions found.</div>;
    }
    const { similarProblemsLookup } = this;
    const sortedSolutions = _.orderBy(solutions, (solution) => {
      const similarProblems = similarProblemsLookup.get(solution.id) || [];
      return similarProblems.length;
    }, 'desc');
    return (
      <div>
        {sortedSolutions.map(solution => (
          <Solution
            key={solution.id}
            solution={solution}
            problem={this.props.problem}
            similarProblems={similarProblemsLookup.get(solution.id) || []}
          />
        ))}
      </div>
    );
  }

  private get similarProblemsLookup() {
    const lookup = new Map<string, ProblemQuery_problem_similar[]>();
    this.props.similarProblems.forEach(problem => {
      problem.reviews.forEach(review => {
        const { solutionId, status } = review;
        if (!([ReviewStatus.FullFix, ReviewStatus.PartialFix].includes(status))) {
          return;
        }
        const group = lookup.get(solutionId);
        if (Array.isArray(group)) {
          group.push(problem);
        } else {
          lookup.set(solutionId, [problem]);
        }
      });
    });
    return lookup;
  }
}

export interface SolutionsProps {
  problem: Problem;
  solutions: ProblemSolution[];
  similarProblems: ProblemQuery_problem_similar[];
}

export interface SolutionsState {}
