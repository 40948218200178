import * as React from "react";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import * as _ from "lodash";

import { StackTraceKind, ProblemsFilter } from "../../../graphql";
import { StackTraceIcon } from "../../../components/StackTraceIcon";
import { SelectProjectDropdown } from "./SelectProjectDropdown";
import { ProjectsQuery_viewer_user_projects as Project } from "../../../graphql";

export class ProblemSearchInput extends React.Component<
  ProblemSearchInputProps,
  ProblemSearchInputState
> {
  public state: ProblemSearchInputState = {
    filter: {
      message: "",
      projectIds: [],
      userIds: [],
    },
  };

  constructor(props: ProblemSearchInputProps) {
    super(props);
    this.state.filter = props.filter;
  }

  public componentWillReceiveProps(nextProps: ProblemSearchInputProps) {
    this.setState({
      filter: nextProps.filter,
    });
  }

  public render() {
    const { kinds } = this;
    return (
      <div>
        <InputGroup className="problem-search-input">
          <Input
            placeholder="Filter by error message"
            value={this.message}
            onChange={event => {
              const newMessage = event.target.value;
              this.changeFilter({
                message: newMessage,
              });
            }}
          />

          {!this.props.disableProjectSelect && (
            <SelectProjectDropdown
              projectIds={this.projectIds}
              toggleProject={this.toggleProject}
            />
          )}

          <UncontrolledButtonDropdown addonType="append">
            <DropdownToggle caret outline color="secondary">
              {kinds.length === 0 ? (
                "Filter by types"
              ) : (
                <span>{kinds.join(" or ")}</span>
              )}
            </DropdownToggle>
            <DropdownMenu>
              {Object.values(StackTraceKind).map((kind: StackTraceKind) => {
                const active = this.isKindActive(kind);
                const onClick = () => {
                  this.toggleKind(kind);
                };
                return (
                  <StackTraceKindFilter
                    key={kind}
                    onClick={onClick}
                    kind={kind}
                    active={active}
                  />
                );
              })}
            </DropdownMenu>
          </UncontrolledButtonDropdown>

          <InputGroupAddon addonType="append">
            <Button color="info" disabled={!this.isChanged} onClick={this.sync}>
              <FontAwesomeIcon icon={faSearch} /> Search
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    );
  }

  private get isChanged() {
    return (
      JSON.stringify(this.state.filter) !== JSON.stringify(this.props.filter)
    );
  }

  private toggleKind(kind: StackTraceKind) {
    const { kinds } = this;
    const newKinds = this.isKindActive(kind)
      ? _.difference(kinds, [kind])
      : kinds.concat([kind]);
    this.changeFilter({
      kinds: newKinds,
    });
  }

  private toggleProject = (project: Project) => {
    const projectId = project.id;
    const { projectIds } = this;
    const newProjectIds = this.isProjectActive(projectId)
      ? _.difference(projectIds, [projectId])
      : projectIds.concat([projectId]);
    this.changeFilter({
      projectIds: newProjectIds,
    });
  };

  private isKindActive(kind: StackTraceKind) {
    return this.kinds.some(curr => curr === kind);
  }

  private isProjectActive(projectId: string) {
    return this.projectIds.some(curr => curr === projectId);
  }

  private get kinds() {
    return this.filter.kinds || [];
  }

  private get projectIds() {
    return this.filter.projectIds || [];
  }

  private get message() {
    return this.filter.message || "";
  }

  private get filter() {
    return this.state.filter;
  }

  private changeFilter = (newFilter: ProblemsFilter) => {
    this.setState(prevState => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        ...newFilter,
      },
    }));
  };

  private sync = () => {
    this.props.changeFilter(this.state.filter);
  };
}

export interface ProblemSearchInputProps {
  disableProjectSelect: boolean;
  filter: ProblemsFilter;
  changeFilter(filter: ProblemsFilter): void;
}

interface ProblemSearchInputState {
  filter: ProblemsFilter;
}

const StackTraceKindFilter = ({
  kind,
  active,
  onClick,
}: {
  kind: StackTraceKind;
  active: boolean;
  onClick(event: any): void;
}) => (
  <DropdownItem active={active} onClick={onClick}>
    <StackTraceIcon kind={kind} /> {kind}
  </DropdownItem>
);
