import * as React from "react";
import MDX from "@mdx-js/runtime";

import SyntaxHighlighter from "./SyntaxHighlighter";
import { Diff } from "./Diff";

export class MDXView extends React.Component<{ children: string }> {
  public render() {
    const { children } = this.props;
    return (
      <MDX
        components={components}
        scope={scope}
        children={children.toString()}
      />
    );
  }
}

const components = {
  code: (props: any) => {
    const { className = "" } = props;
    const match = className.match(/language-([a-z]+)/i);
    const language = match && match[1];
    if (language === "diff") {
      return <Diff children={props.children} />;
    }
    return (
      <SyntaxHighlighter
        language={language}
        showLineNumbers={false}
        wrapLines={false}
        children={props.children}
      />
    );
  },
  pre: (props: any) => {
    const isCodeBlock = Boolean(
      props.children &&
        props.children.props &&
        props.children.props.name === "code"
    );
    if (isCodeBlock) {
      return <div>{props.children}</div>;
    }
    return <pre {...props} />;
  },
};

// Provide custom components that will be referenced as JSX
// in the markdown string
const scope = {
  Demo: (props: any) => <div>This is a demo React component</div>,
  HomePath: (props: any) => <span>/home/user/</span>,
};
