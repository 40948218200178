import * as React from "react";

import { PrivateRoute } from "../../Auth/PrivateRoute";
import { Problem } from "./Problem";
import Setup from "../Setup";

export default function Search() {
  return (
    <div>
      <PrivateRoute exact path="/search" component={Setup} />
      <PrivateRoute
        path="/search/:problemId"
        render={({ match }) => <Problem problemId={match.params.problemId} />}
      />
    </div>
  );
}
