import * as React from "react";
import {
  CardTitle,
} from "reactstrap";

import { Problem as ProblemView } from "../../../History/Problem";
import { ProblemQuery_problem_similar } from "../../../../graphql";

export function SimilarProblemsContainer({ similarProblems }: SimilarProblemsContainer) {
  if (similarProblems.length === 0) {
    return null;
  }
  return (
    <div className="similar-problems-container">
      <CardTitle>
        Similar Problems ({similarProblems.length})
      </CardTitle>
      {similarProblems.map(similarProblem => (
        <ProblemView problem={similarProblem} key={similarProblem.id} />
      ))}
    </div>
  );
}

export interface SimilarProblemsContainer {
  similarProblems: ProblemQuery_problem_similar[];
}
