import { Alert, Form, Label, Col, FormGroup, Input } from "reactstrap";
import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import * as viewerQuery from "../../graphql/queries/viewer.graphql";
import { ViewerQuery } from "../../graphql";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import Button from "reactstrap/lib/Button";

import { SelectProject } from "../../components/SelectProject";
import { Create as CreateProject } from "../Projects/Create";
import { RouteComponentProps } from "react-router";
import { Step } from "./Step";
import { ContentsWithAsciinema } from "./ContentsWithAsciinema";
import { Link } from "react-router-dom";

export class Setup extends React.Component<SetupProps, SetupState> {
  state = {
    projectId: undefined,
  };

  public render() {
    return (
      <div>
        <Query query={viewerQuery} variables={{}}>
          {(queryResult: QueryResult<ViewerQuery>) => {
            const { loading, error, data } = queryResult;
            if (loading) {
              return <LoadingSpinner />;
            }
            if (error || !data) {
              console.error(error);
              return (
                <Alert color="danger">
                  <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
                  {error && error.message}
                </Alert>
              );
            }
            const { viewer } = data;
            if (!viewer) {
              console.log("No viewer");
              return <LoadingSpinner />;
            }
            const { user } = viewer;
            if (!user) {
              console.log("No user");
              return <LoadingSpinner />;
            }
            const { projectId } = this.state;
            return (
              <div className="setup">
                <Step num={1} title="Setup your project">
                  <div>
                    All problems are associated with a project.
                    <SelectProject
                      projectId={projectId}
                      onChange={this.setProjectId}
                      placeholder="Create a new project"
                    />
                    {!projectId && (
                      <>
                        <div>
                          You can simply name your first project "Test" and can
                          rename it later.
                          <CreateProject
                            {...this.props}
                            onCreateProject={this.setProjectId}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </Step>

                <Step num={2} title="Install the CodePass CLI">
                  <div>
                    <strong>Prerequisite</strong>:{" "}
                    <a href="https://nodejs.org/" target="_blank">
                      Node.js
                    </a>{" "}
                    is installed.
                  </div>
                  <div>
                    With{" "}
                    <a href="https://www.npmjs.com/" target="_blank">
                      npm
                    </a>{" "}
                    run the following:
                    <pre>{`npm install --global @codepass/cli`}</pre>
                  </div>
                  <div>
                    Or with{" "}
                    <a href="https://yarnpkg.com/" target="_blank">
                      yarn
                    </a>{" "}
                    run the following:
                    <pre>{`yarn global add @codepass/cli`}</pre>
                  </div>
                  <div>
                    Then to confirm it is successfully installed run the
                    following:
                    <pre>{`codepass --help`}</pre>
                  </div>
                  <Button
                    target="_blank"
                    href="https://npmjs.com/package/@codepass/cli"
                    color="primary"
                  >
                    Learn more about the CodePass CLI
                  </Button>
                </Step>

                <Step num={3} title="Configure the CodePass CLI">
                  <ContentsWithAsciinema
                    asciinema={{
                      width: 691,
                      height: 524,
                      videoId: "221477",
                    }}
                  >
                    <div>
                      Perform the following for every one of your projects.
                    </div>
                    <div>
                      This step creates two (2) configuration files:
                      <dl>
                        <dt>Personal / global configuration</dt>
                        <dd>
                          <kbd>~/.codepassrc.yml</kbd> in the user's home
                          directory
                        </dd>

                        <dt>Project specific configuration</dt>
                        <dd>
                          <kbd>.codepassrc.yml</kbd> in the project's root
                          directory
                        </dd>
                      </dl>
                    </div>
                    <div>
                      Change your current working directory to the root
                      directory of your specific project.
                    </div>
                    <div>
                      Run the following command using the the CodePass CLI:
                      <pre>{"codepass init"}</pre>
                    </div>
                    <div>
                      <div>
                        Follow the instructions in your shell and copy and paste
                        the following details when asked:
                      </div>
                      <br />
                      <div>
                        <Form>
                          <FormGroup row>
                            <Label for="email" sm={2}>
                              Email
                            </Label>
                            <Col sm={10}>
                              <Input
                                type="email"
                                name="email"
                                id="email"
                                readOnly
                                value={user.email}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="uploadToken" sm={2}>
                              Upload Token
                            </Label>
                            <Col sm={10}>
                              <Input
                                type="text"
                                name="uploadToken"
                                id="uploadToken"
                                readOnly
                                value={user.uploadToken}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="uploadToken" sm={2}>
                              Project Name
                            </Label>
                            <Col sm={10}>
                              <SelectProject
                                projectId={projectId}
                                readOnly
                                placeholder="Select project above"
                              />
                            </Col>
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </ContentsWithAsciinema>
                </Step>

                <Step num={4} title="Send your problems to CodePass">
                  <div>
                    Search with CodePass by sending your problems via the CLI.
                    You currently have two (2) options for sending problems:
                  </div>
                  <div>
                    <details>
                      <summary>
                        <strong>1. File contents</strong>
                      </summary>
                      <div>
                        <ContentsWithAsciinema
                          asciinema={{
                            width: 777,
                            height: 524,
                            videoId: "221495",
                          }}
                        >
                          To send a file to CodePass use the following command:
                          <pre>codepass problem &lt;file&gt;</pre>
                          You can use a shorter alias <kbd>p</kbd> instead of{" "}
                          <kbd>problem</kbd>:
                          <pre>codepass p &lt;file&gt;</pre>
                          Replace the <kbd>&lt;file&gt;</kbd> argument with the
                          relative path to your file containing the problem's
                          logs.
                          <div>
                            For example, the contents of <kbd>&lt;file&gt;</kbd>{" "}
                            could be a JavaScript error:
                            <div>
                              <pre
                              >{`Cannot find module './lib/source-map-generator' from 'source-map.js'

at Resolver.resolveModule (node_modules/jest-resolve/build/index.js:210:17)
at Object.<anonymous> (node_modules/source-map-support/node_modules/source-map/source-map.js:6:30)
`}</pre>
                            </div>
                          </div>
                        </ContentsWithAsciinema>
                      </div>
                    </details>

                    <details>
                      <summary>
                        <strong>2. Clipboard contents</strong>
                      </summary>
                      <div>
                        If you do not already have a file and would prefer not
                        to create one then you can read from your clipboard. To
                        send your clipboard contents to CodePass use the
                        following command:
                        <pre>codepass problem -</pre>
                        Using the <kbd>-</kbd> argument in place for the file
                        name (i.e. <kbd>&lt;file&gt;</kbd>) tells the CLI not to
                        use a real file and instead read from your clipboard.
                      </div>
                    </details>
                  </div>
                  {/* <div>
                    <Button
                      color="success"
                      target="_blank"
                      href="https://codepass.netlify.com/search/d6d2f66e-5707-496f-a7f6-a27168d02974"
                    >
                      View the example problem
                    </Button>
                  </div> */}
                </Step>

                <Step num={5} title="Next steps">
                  <div>
                    See all of the available commands by running:
                    <pre>
                      {
                        "$ codepass --help\n...\nExamples:\n  codepass problem my-problem.log    Send your problem for CodePass to analyze"
                      }
                    </pre>
                    See usage examples by running:
                    <pre>
                      {
                        "$ codepass problem --help\n...\nExamples:\n  codepass problem my-problem.log    Send your problem from a file for CodePass to analyze\n  codepass problem -                 Send your problem from the clipboard for CodePass to analyze"
                      }
                    </pre>
                  </div>
                  <div>
                    After you've sent your first problem to CodePass you can{" "}
                    <Button tag={Link} to="/problems" color="primary">
                      continue to the Problems page
                    </Button>
                  </div>
                </Step>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }

  private setProjectId = (projectId: string | undefined): void => {
    this.setState({
      projectId,
    });
  };
}

interface SetupProps extends RouteComponentProps<{}, {}> {}

interface SetupState {}

export default Setup;
