import * as React from "react";
import { Col, Row } from "reactstrap";

import { AsciinemaPlayer, AsciinemaPlayerProps } from "../../components/AsciinemaPlayer";

export function ContentsWithAsciinema(props: ContentsWithAsciinemaProps) {
  return (
    <Row>
      <Col xl="6" lg="12">
        {props.children}
      </Col>
      <Col xl="6" lg="12" className="demo">
        <h5>Demonstration</h5>
        <div>
          <AsciinemaPlayer {...props.asciinema} />
        </div>
      </Col>
    </Row>
  );
}

export interface ContentsWithAsciinemaProps {
  children: React.ReactNode;
  asciinema: AsciinemaPlayerProps;
}
