import * as React from "react";
import * as _ from "lodash";

import * as githubIcon from "../../../../images/solution/source/github.svg";
import * as stackoverflowIcon from "../../../../images/solution/source/stackoverflow.svg";
import * as codepassIcon from "../../../../images/solution/source/codepass.svg";
import { UncontrolledTooltip } from "reactstrap";

export function SourceIcon({ solutionId, source }: SourceIconProps) {
  const solutionIdSelector = CSS.escape(solutionId);
  const imgSrc = imageSrcForSource(source);
  return (
    <React.Fragment>
      <span id={`source-${solutionId}`} className="solution-source-icon">
          <img src={imgSrc} />
      </span>
      <UncontrolledTooltip
        placement="left"
        target={`source-${solutionIdSelector}`}
      >
        {source}
      </UncontrolledTooltip>
    </React.Fragment>
  );
}

function imageSrcForSource(source: string) {
  switch (source) {
    case "GitHub": return githubIcon;
    case "StackOverflow": return stackoverflowIcon;
    case "CodePass": return codepassIcon;
  }
  throw new Error(`Unknown Solution source: ${source}`);
}

export interface SourceIconProps {
  solutionId: string;
  source: string;
}
