import * as React from "react";
import TimeAgo from "react-timeago";
import { Row, Col, Badge, Button, Card, CardBody } from "reactstrap";
import { MutateProps } from "react-apollo";
import classNames = require("classnames");
import gravatarUrl = require("gravatar-url");

import {
  ProjectQuery_project as Project,
  RemoveMemberFromProjectVariables,
  ProjectQuery_project_members,
} from "../../../../graphql";
import { parseGraphQLDate } from "../../../../utils/parseGraphQLDate";
import { ProblemsSparkline } from "../../../../containers/ProblemsSparkline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export const TeamMembers = (props: TeamProps) => {
  const { project } = props;
  const { owner, members } = project;
  return (
    <React.Fragment>
      <TeamMember
        {...props}
        member={{
          id: owner.id,
          name: owner.name,
          email: owner.email,
          createdAt: owner.createdAt,
          updatedAt: owner.updatedAt,
          uploadToken: undefined as any, // FIXME
        }}
        role={MemberRole.Owner}
      />
      {members.map(member => (
        <TeamMember
          key={member.id}
          {...props}
          member={member}
          role={MemberRole.Developer}
        />
      ))}
    </React.Fragment>
  );
};

export const TeamMember = (props: TeamMemberProps) => {
  const { member, project, role } = props;
  const removeMemberFromProject = props.mutate;
  const canRemove: boolean = role !== MemberRole.Owner;
  return (
    <Card>
      <CardBody>
        <Row key={member.id} className="team-member">
          <Col xs="12" sm="7" md="5" lg="5" xl="4">
            <img
              src={gravatarUrl(member.email, { size: 80 })}
              alt="avatar"
              className="team-member-avatar img-thumbnail rounded-circle pull-left"
            />

            {canRemove && (
              <RemoveButton
                className="d-block d-sm-none"
                onClick={() =>
                  removeMemberFromProject({
                    variables: {
                      projectId: project.id,
                      userId: member.id,
                    },
                  })
                }
              />
            )}

            <div className="info-heading">
              <span>{member.name}</span>{" "}
              {role === MemberRole.Owner && (
                <Badge color={"success"}>{role}</Badge>
              )}
            </div>
            <div className="info-sub-heading">
              <a href={`mail:${member.email}`}>{member.email}</a>
              <br />
              <div>
                <span>Joined </span>
                <TimeAgo date={parseGraphQLDate(member.createdAt)} />
              </div>
            </div>
          </Col>

          <Col xs="0" sm="5" md="7" lg="7" xl="8" className="d-none d-sm-block">
            {canRemove && (
              <RemoveButton
                onClick={() =>
                  removeMemberFromProject({
                    variables: {
                      projectId: project.id,
                      userId: member.id,
                    },
                  })
                }
              />
            )}
            <ProblemsSparkline projectId={project.id} userId={member.id} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

function RemoveButton(props: { onClick: Function; className?: string }) {
  return (
    <Button
      color="primary"
      className={classNames("pull-right", props.className)}
      onClick={props.onClick as any}
    >
      <FontAwesomeIcon icon={faTimes} /> Remove
    </Button>
  );
}

enum MemberRole {
  Owner = "Owner",
  Developer = "Developer",
}

type TeamMemberProps = TeamProps & {
  member: ProjectQuery_project_members;
  role: MemberRole;
};

type TeamProps = MutateProps<any, RemoveMemberFromProjectVariables> & {
  project: Project;
};
