import * as React from "react";
import {
  Button,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap";
import { Link } from "react-router-dom";

import { ProjectsQuery_viewer_user_projects } from "../../graphql";

import { ProblemsSparkline } from "../../containers/ProblemsSparkline";

export const Project = ({
  project,
}: {
  project: ProjectsQuery_viewer_user_projects;
}) => {
  const to = `/projects/edit/${project.id}`;
  return (
    <ListGroupItem className="project-item">
      <Button
        tag={Link}
        className="pull-right"
        color="secondary"
        outline
        to={to}
      >
        Edit
      </Button>
      <ListGroupItemHeading>
        <Link to={to}>{project.name}</Link>
      </ListGroupItemHeading>
      <ListGroupItemText>
        <ProblemsSparkline projectId={project.id} />
      </ListGroupItemText>
    </ListGroupItem>
  );
};
