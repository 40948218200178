import * as React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import { NavLink as RouterNavLink, Link } from "react-router-dom";

import { AuthContainer } from "../Auth/AuthContainer";
import { Subscribe } from "unstated";

export class NavBar extends React.Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    return (
      <div className="pb-3">
        <Navbar dark expand="md">
          <NavbarBrand tag={Link} to="/">
            CodePass
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="" navbar>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/problems"
                  activeClassName="active"
                >
                  Problems
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/projects"
                  activeClassName="active"
                >
                  Projects
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/analytics"
                  activeClassName="active"
                >
                  Analytics <Badge color="info">Coming soon</Badge>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/search"
                  activeClassName="active"
                >
                  Help
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="ml-auto" navbar>
              <Subscribe to={[AuthContainer]}>
                {(auth: AuthContainer) => (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      {auth.userInfo && auth.userInfo.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>
                        <Link to="/account">Account</Link>
                      </DropdownItem>
                      <DropdownItem>
                        <Link to="/projects/create">Create Projects</Link>
                      </DropdownItem>
                      {/*
                      <DropdownItem
                        onClick={() => window.alert("Teams coming soon")}
                      >
                        Create Team
                      </DropdownItem>
                      */}
                      <DropdownItem divider />
                      <DropdownItem onClick={auth.logout}>
                        Sign out
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </Subscribe>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

interface NavBarProps {}

interface NavBarState {
  isOpen: boolean;
}
