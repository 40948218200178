import * as React from "react";
import * as _ from "lodash";

import {
  SolutionExternalFields_reviews as Reviews,
  ReviewStatus,
} from "../../../../graphql";

export function HiddenMessage({ negativeReviews }: HiddenMessageProps) {
  const messages = _.uniq(
    negativeReviews.map(review => reviewStatusToMessage(review.status))
  );
  return <span>Hidden for being {messages.join(' and ')}. Click to show.</span>;
}

function reviewStatusToMessage(status: ReviewStatus): string {
  switch (status) {
    case ReviewStatus.Irrelevant:
      return "irrevelant";
    case ReviewStatus.NotAFix:
    default:
      return "not a fix";
  }
}

export interface HiddenMessageProps {
  negativeReviews: Reviews[];
}
