import * as React from "react";

import { PrivateRoute } from "../../Auth/PrivateRoute";
import { List } from "./List";
import { CreateProjectPage as Create } from "./Create";
import { Edit } from "./Edit";

export default function Projects() {
  return (
    <div>
      <PrivateRoute exact path="/projects" component={List} />
      <PrivateRoute exact path="/projects/create" component={Create} />
      <PrivateRoute
        path="/projects/edit/:projectId"
        component={Edit}
      />
    </div>
  );
}
