import * as React from "react";

import { TabContext, TabContextState, Tab } from "./TabContext";

export class TabContainer extends React.Component<
  TabContainerProps,
  TabContextState
> {
  constructor(props: TabContainerProps) {
    super(props);
    this.state = {
      activeTab: this.props.defaultTab,
      setTab: this.setTab,
    };
  }

  private setTab = (tab: Tab) => {
    this.setState(prevState => ({
      ...prevState,
      activeTab: tab,
    }));
  };

  public render() {
    return (
      <TabContext.Provider value={this.state}>
        {this.props.children}
      </TabContext.Provider>
    );
  }
}

export interface TabContainerProps {
  defaultTab: Tab;
}
