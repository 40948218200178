import * as React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { Subscribe } from "unstated";

import { AuthContainer } from "./AuthContainer";

export const PrivateRoute = ({
  component: Component,
  render,
  ...rest
}: RouteProps) => (
  <Subscribe to={[AuthContainer]}>
    {(auth: AuthContainer) => (
      <Route
        {...rest}
        render={props =>
          auth.isAuthenticated ? (
            Component ? (
              <Component {...props} />
            ) : (
              render && render(props)
            )
          ) : (
            <Redirect to="/" />
          )
        }
      />
    )}
  </Subscribe>
);
