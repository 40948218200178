import * as React from "react";

import * as githubIcon from "../images/solution/source/github.svg";
import * as stackoverflowIcon from "../images/solution/source/stackoverflow.svg";
import * as codepassIcon from "../images/solution/source/codepass.svg";

export class SolutionsLoader extends React.Component<{}, SolutionsLoaderState> {
  private icons = [githubIcon, stackoverflowIcon, codepassIcon];

  state: SolutionsLoaderState = {
    timer: null,
    startDate: null,
    iconInterval: 3000,
    expectedDuration: 20000,
    milliseconds: 0,
    tickInterval: 10,
  };

  componentDidMount() {
    const timer: number = setInterval(() => {
      this.next();
    }, this.state.tickInterval);
    this.setState({
      timer,
      startDate: new Date(),
    });
  }

  componentWillUnmount() {
    this.state.timer && clearInterval(this.state.timer);
    this.setState({
      timer: null,
      startDate: null,
    });
  }

  public render() {
    return (
      <div className="solutions-loader">
        <div className="loader">
          <div className="image">
            <img src={this.icon} />
          </div>
          <span>{this.percentage}%</span>
        </div>
        <span className="lead">
          Analyzing solutions from available sources...
        </span>
      </div>
    );
  }

  private get percentage() {
    return Math.min(
      99,
      Math.floor(this.state.milliseconds / this.state.expectedDuration * 100)
    );
  }

  private get iconIndex() {
    return (
      Math.floor(this.state.milliseconds / this.state.iconInterval) %
      this.icons.length
    );
  }

  private next = () => {
    this.setState(prevState => ({
      ...prevState,
      milliseconds: prevState.startDate
        ? +new Date() - +prevState.startDate
        : prevState.milliseconds,
    }));
  };

  private get icon() {
    return this.icons[this.iconIndex];
  }
}

interface SolutionsLoaderState {
  timer: number | null;
  startDate: Date | null;
  expectedDuration: number;
  milliseconds: number;
  iconInterval: number;
  tickInterval: number;
}
