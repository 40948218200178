import * as React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Nav,
  TabPane,
} from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import * as _ from "lodash";

import {
  SolutionInternalFields,
  SolutionExternalFields,
  ReviewStatus,
  SolutionExternalFields_reviews as Reviews,
  ProblemSolutionsQuery_problem as Problem,
  ProblemQuery_problem_similar,
} from "../../../../graphql";
import { Reactions } from "./Reactions";
import { StatusIcon } from "./StatusIcon";
import { SourceIcon } from "./SourceIcon";
import { SolutionTitle } from "./SolutionTitle";
import {
  TabNavItem,
  TabContainer,
  TabContent,
} from "../../../../components/Tabs";
import { SimilarProblemsContainer } from "./SimilarProblemsContainer";
import SolutionReviewStats from "./SolutionReviewStats";
import { MDXView } from "../../../../components/MDXView";
import { ErrorBoundary } from "../../../../components/ErrorBoundary";
import { HiddenMessage } from "./HiddenMessage";

export class Solution extends React.Component<SolutionProps, SolutionState> {
  state = {
    activeTab: Tabs.Details,
    forceShow: false,
  };

  public render() {
    const {
      id: solutionId,
      body,
      source,
      // parent,
    } = this.solution;

    return (
      <Card
        key={solutionId}
        id={`solution-${solutionId}`}
        className="search-result"
      >
        <CardHeader
          className="" tag={this.shouldHide ? "div" : "h4"}
          onClick={this.shouldHide ? this.showHidden : undefined}
        >
          {this.shouldHide ? (
            <HiddenMessage negativeReviews={this.negativeReviews} />
          ) : (
              <React.Fragment>
                <StatusIcon
                  solutionId={this.solutionId}
                  isFullFix={this.isFullFix}
                  reviews={this.reviews}
                  positiveReviews={this.positiveReviews}
                />{" "}
              <a href={this.solutionUrl} target="_blank" rel="noreferrer">
                <SolutionTitle
                  authorName={this.authorName}
                  updatedAt={this.updatedAt}
                />
              </a>
            </React.Fragment>
          )}
          <span className="pull-right">
            <SourceIcon solutionId={solutionId} source={source} />
          </span>
        </CardHeader>
        {!this.shouldHide && (
          <React.Fragment>
            <TabContainer defaultTab={Tabs.Details}>
              <CardHeader className="">
                <Nav tabs className="card-header-tabs">
                  <TabNavItem tab={Tabs.Details}>Solution details</TabNavItem>
                  <TabNavItem tab={Tabs.Why}>Why am I see this?</TabNavItem>
                </Nav>
              </CardHeader>

              <CardBody>
                <TabContent>
                  <TabPane tabId={Tabs.Details}>{this.renderBody(body)}</TabPane>
                  <TabPane tabId={Tabs.Why}>
                    <div>
                      More coming soon!
                    </div>
                    <hr />
                    <SimilarProblemsContainer
                      similarProblems={this.props.similarProblems}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </TabContainer>

            <CardFooter>
              <SolutionReviewStats
                problem={this.props.problem}
                solution={this.solution}
                similarProblems={this.props.similarProblems}
              />{" "}
              {this.reactions && <Reactions reactions={this.reactions} />}
            </CardFooter>
          </React.Fragment>
        )}
      </Card>
    );
  }

  private renderBody(body: string) {
    const { solution } = this.props;
    if (this.isInternalSolution(solution)) {
      return (
        <ErrorBoundary>
          <MDXView>{body}</MDXView>
        </ErrorBoundary>
      );
    }
    return this.renderHTML(body);
  }

  private get updatedAt(): Date {
    return new Date(this.props.solution.updatedAt);
  }

  private get solutionUrl(): string {
    const { solution } = this.props;
    if (this.isInternalSolution(solution)) {
      return "#";
    }
    return solution.htmlUrl;
  }

  private get authorName(): string {
    const { solution } = this.props;
    if (this.isInternalSolution(solution)) {
      return solution.author.name;
    }
    return solution.authorName;
  }

  // FIXME: Backend should abstract this. Frontend should not perform this check. Move to backend API.
  private isInternalSolution(
    solution: SolutionFields
  ): solution is SolutionInternalFields {
    return solution.source === "CodePass";
  }

  private get solutionId() {
    return this.solution.id;
  }

  private get reactions() {
    const { solution } = this.props;
    if (this.isInternalSolution(solution)) {
      return null;
    }
    return solution.reactions;
  }

  private get isFullFix(): boolean {
    return this.reviewsForStatus(ReviewStatus.FullFix).length > 0;
  }

  private get shouldHide(): boolean {
    return !this.state.forceShow && (this.positiveReviews.length === 0 && this.negativeReviews.length > 0);
  }

  private get positiveReviews(): Reviews[] {
    return [
      ...this.reviewsForStatus(ReviewStatus.FullFix),
      ...this.reviewsForStatus(ReviewStatus.PartialFix),
    ];
  }

  private get negativeReviews(): Reviews[] {
    return [
      ...this.reviewsForStatus(ReviewStatus.NotAFix),
      ...this.reviewsForStatus(ReviewStatus.Irrelevant),
    ];
  }

  private reviewsForStatus(status: ReviewStatus): Reviews[] {
    return this.reviews.filter(review => review.status === status);
  }

  private get reviews() {
    return this.solution.reviews;
  }

  private get solution() {
    return this.props.solution;
  }

  private renderHTML(html: string) {
    return ReactHtmlParser(html, {
      transform: this.transform,
    });
  }

  private transform(node: any) {
    if (node.type === "tag") {
      if (node.name === "a") {
        node.attribs.target = "_blank";
      }
    }
    return;
  }

  private showHidden = () => {
    this.setState(prevState => ({
      ...prevState,
      forceShow: true,
    }));
  }
}

export default Solution;

type SolutionFields = SolutionInternalFields | SolutionExternalFields;

export type SolutionProps = {
  problem: Problem;
  solution: SolutionFields;
  similarProblems: ProblemQuery_problem_similar[];
};

interface SolutionState {
  activeTab: Tabs;
  forceShow: boolean;
}

enum Tabs {
  Details,
  Why,
}
