import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "reactstrap";

export class ErrorBoundary extends React.PureComponent<
  { children: React.ReactNode },
  { error?: Error }
> {
  public state: { error?: Error } = {
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  public render() {
    const { error } = this.state;
    if (error) {
      return (
        <Alert color="danger">
          <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
          {error && error.message}
        </Alert>
      );
    }
    return this.props.children;
  }
}
