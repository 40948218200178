import { createBrowserHistory as createHistory } from "history";

import { pageView } from "./GoogleAnalytics";

const history = createHistory();

export default history;

history.listen(function(location) {
  const pagePath = location.pathname + location.search;
  const pageTitle = document.title;
  pageView({ path: pagePath, title: pageTitle });
});
