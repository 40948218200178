import * as React from "react";
import { Alert, ListGroupItem, Badge } from "reactstrap";
import { Query, QueryResult } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faAngleDown,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

import * as problemDependenciesQuery from "../../../../graphql/queries/problemDependencies.graphql";
import {
  ProblemDependenciesQuery,
  ProblemDependenciesQueryVariables,
  ProblemDependenciesQuery_problem_dependenciesList,
} from "../../../../graphql";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";

export class SubDependency extends React.Component<
  SubDependencyProps,
  SubDependencyState
> {
  public state = {
    isOpen: false,
  };

  public render() {
    const { dependency: dep } = this.props;
    return (
      <React.Fragment>
        <ListGroupItem key={dep.name} onClick={this.toggle}>
          {this.renderIndentation()}
          {dep.subs ? (
            <FontAwesomeIcon
              icon={this.state.isOpen ? faAngleDown : faAngleRight}
            />
          ) : (
            <span>&nbsp;&nbsp;</span>
          )}{" "}
          {dep.name}
          {" "}
          <Badge pill className="pull-right">
            {dep.version}
          </Badge>
        </ListGroupItem>
        {dep.subs && this.state.isOpen && this.renderSubDeps()}
      </React.Fragment>
    );
  }

  private renderIndentation() {
    return (
      <span className="indentation">
        {this.props.parents.map(parent => (
          <span key={parent}>&nbsp;&nbsp;&nbsp;</span>
        ))}
      </span>
    );
  }

  private renderSubDeps() {
    return (
      <Query query={problemDependenciesQuery} variables={this.queryVariables}>
        {(queryResult: QueryResult<ProblemDependenciesQuery>) => {
          const { loading, error, data } = queryResult;
          if (loading) {
            return (
              <LoadingSpinner />
            );
          }
          if (error || !data) {
            console.error(error);
            return (
              <Alert color="danger">
                <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
                {error && error.message}
              </Alert>
            );
          }
          const { problem } = data;
          const { dependenciesList } = problem;
          return (
            <React.Fragment>
              {dependenciesList.map((dep, index) => (
                <SubDependency
                  key={`${dep.name}@${dep.version}`}
                  problemId={this.props.problemId}
                  parents={this.queryVariables.parents}
                  dependency={dep}
                />
              ))}
            </React.Fragment>
          );
        }}
      </Query>
    );
  }

  private toggle = () => {
    this.setState(prevState => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  private get queryVariables(): ProblemDependenciesQueryVariables {
    return {
      projectId: this.props.problemId,
      parents: this.props.parents.concat([this.props.dependency.name]),
    };
  }
}

export interface SubDependencyProps {
  problemId: string;
  parents: ProblemDependenciesQueryVariables["parents"];
  dependency: ProblemDependenciesQuery_problem_dependenciesList;
}

export interface SubDependencyState {
  isOpen: boolean;
}
