import * as React from "react";
import * as _ from "lodash";

import { ProblemQuery_problem as Problem } from "../../../graphql";
import { Form, FormGroup, Label, Col, Input } from "reactstrap";

export class ProblemEnvironment extends React.Component<
  ProblemEnvironmentProps,
  {}
> {
  public render() {
    const { environment } = this.props;
    const { user, variables, executables } = environment;
    return (
      <div>
        <User user={user} />
        <Variables envVars={variables} />
        <Executables executables={executables} />
      </div>
    );
  }
}

function User({ user }: { user: Problem["environment"]["user"] }) {
  if (!user) {
    return <div>No user information found.</div>;
  }
  return (
    <Section title="User">
      <Form>
        <Field label="User name" value={user.name} />
        <Field label="Home directory" value={user.homeDir} />
      </Form>
    </Section>
  );
}

function Variables({
  envVars,
}: {
  envVars: Problem["environment"]["variables"];
}) {
  if (envVars.length === 0) {
    return <div>No environment variables found.</div>;
  }
  const rows = _.orderBy(
    envVars,
    envVar => envVar.name.toLowerCase(),
    "asc"
  ).map(envVar => (
    <Field
      key={envVar.name}
      label={envVar.name}
      value={envVar.value || ""}
      hidden={envVar.isHidden}
      empty={envVar.isEmpty}
    />
  ));
  return (
    <Section title="Environment variables">
      <Form>{rows}</Form>
    </Section>
  );
}

function Executables({
  executables,
}: {
  executables: Problem["environment"]["executables"];
}) {
  if (executables.length === 0) {
    return <div>No executables information found.</div>;
  }
  const rows = _.orderBy(executables, exec => exec.name.toLowerCase(), "asc")
    .filter(envVar => !!envVar.version)
    .map(envVar => (
      <Field
        key={envVar.name}
        label={envVar.name}
        value={envVar.version || ""}
      />
    ));
  return (
    <Section title="Executables">
      <Form>{rows}</Form>
    </Section>
  );
}

function Section({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <details>
      <summary>
        <strong>{title}</strong>
      </summary>
      {children}
    </details>
  );
}

function Field({
  label,
  value,
  empty = false,
  hidden = false,
}: {
  label: string;
  value: string;
  empty?: boolean;
  hidden?: boolean;
}) {
  return (
    <FormGroup row>
      <Label for={label} sm={4}>
        {label}
      </Label>
      <Col sm={8}>
        <Input
          type={hidden ? "password" : "text"}
          name={label}
          readonly
          value={hidden && !empty ? "*".repeat(100) : value}
        />
      </Col>
    </FormGroup>
  );
}

export interface ProblemEnvironmentProps {
  environment: Problem["environment"];
}
