import * as React from "react";
import { MutateProps, graphql, compose } from "react-apollo";

import {
  ProjectQuery_project as Project,
  RemoveMemberFromProjectVariables,
} from "../../../../graphql";
import { AddUser } from "./AddUser";
import * as removeMemberFromProjectMutation from "../../../../graphql/mutations/removeMemberFromProject.graphql";

import { TeamMembers } from "./TeamMembersList";

export const TeamInternal = (props: TeamProps) => {
  return (
    <div className="team">
      <AddUser project={props.project} />
      <TeamMembers {...props} />
    </div>
  );
};

type TeamProps = MutateProps<any, RemoveMemberFromProjectVariables> & {
  project: Project;
};

export const Team = compose(
  graphql(removeMemberFromProjectMutation, {
    options: {
      refetchQueries: ["ProjectsQuery"],
    },
  })
)(TeamInternal);
