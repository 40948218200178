import * as React from "react";
import {
  CardBody,
  CardTitle,
  Alert,
  CardFooter,
} from "reactstrap";
import { parseDiff, Diff } from "react-diff-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCode, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { ProblemCommitsQuery_problem_commits } from "../../../graphql";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

export class CommitDiff extends React.PureComponent<CommitDiffProps, CommitDiffState> {
  public state: CommitDiffState = {
    loading: true,
    error: undefined,
    diff: undefined,
  };

  private changesLimit: number = 5;

  public async componentWillMount() {
    try {
      const diff = await this.fetchDiff(this.props.commit.diffUrl);
      this.setState(prevState => ({
        ...prevState,
        loading: false,
        diff,
      }));
    } catch (error) {
      this.setState(prevState => ({
        ...prevState,
        loading: false,
        error,
        diff: undefined,
      }))
    }
  }

  private fetchDiff(diffUrl: string): Promise<string> {
    return fetch(diffUrl, {
      headers: { Accept: "application/vnd.github.v3.diff" },
    })
      .then(res => res.text());
  }

  public render() {
    if (this.state.loading) {
      return (
        <LoadingSpinner />
      );
    }
    const { error, diff = '' } = this.state;
    if (error) {
      console.error(error);
      return (
        <Alert color="danger">
          <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
          {error && error.message}
        </Alert>
      );
    }

    const { commit } = this.props;
    const parsedDiff = this.parseDiff(diff);
    return (
      <React.Fragment>
        {parsedDiff.map(({ hunks, oldPath, newPath }: any, i: number) => {
        return (
          <React.Fragment key={`${oldPath}-${newPath}`}>
            <CardBody>
              <CardTitle>
                <FontAwesomeIcon icon={faFileCode} /> {newPath === '/dev/null' ? oldPath : newPath}
              </CardTitle>
            </CardBody>
            <div>
              {hunks.length > this.changesLimit ? (
                <CardBody>
                  Too many changes. Please go to <a href={commit.commitUrl} target="_blank">{commit.commitUrl}</a> to view more.
                </CardBody>
              ) : (
                <Diff key={i} hunks={hunks} viewType="split" />
              )}
            </div>
          </React.Fragment>
        )}
      )}
      <CardFooter>{parsedDiff.length} file(s) changed</CardFooter>
    </React.Fragment>
    );
  }

  private parseDiff(diff: string): any[] {
    try {
      return parseDiff(diff);
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}

export interface CommitDiffProps {
  commit: ProblemCommitsQuery_problem_commits;
}

interface CommitDiffState {
  loading: boolean;
  error?: Error;
  diff?: string;
}
