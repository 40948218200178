import * as React from "react";
import * as ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  Collapse,
  Label,
  Form,
  FormGroup,
  Input,
  Nav,
  TabPane,
} from "reactstrap";
import { compose, graphql, MutateProps } from "react-apollo";
import { ApolloQueryResult } from "apollo-client";

import { ProblemQuery_problem as Problem } from "../../../graphql";
import * as createSolutionMutation from "../../../graphql/mutations/createSolution.graphql";
import { CreateSolution, CreateSolutionVariables } from "../../../graphql";
import { MDXView } from "../../../components/MDXView";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import { TabNavItem, TabContainer, TabContent } from "../../../components/Tabs";

export class SolutionInputInternal extends React.Component<
  SolutionInputProps,
  SolutionInputState
> {
  public state: SolutionInputState = {
    expanded: false,
    contents: `# Hello, world!

<Demo />

\`\`\`javascript
console.log('hello world');
\`\`\`


\`\`\`diff
- console.log('hello');
+ console.log('world');
\`\`\`

`,
  };

  public render() {
    const { expanded, contents } = this.state;
    ReactTooltip.rebuild();
    return (
      <Card className="solution-input">
        <CardHeader>
          <h3 onClick={this.toggle}>
            <span>Share your solution</span>
            <span
              className={"pull-right"}
              data-tip={expanded ? "Hide" : "Show"}
            >
              <FontAwesomeIcon
                icon={expanded ? faMinusSquare : faPlusSquare}
                size="lg"
              />
            </span>
          </h3>
        </CardHeader>
        <Collapse isOpen={expanded}>
          <CardBody>
            <TabContainer defaultTab={Tabs.Write}>
              <Nav tabs>
                <TabNavItem tab={Tabs.Write}>
                  Write
                </TabNavItem>
                <TabNavItem tab={Tabs.Preview}>
                  Preview
                </TabNavItem>
              </Nav>

              <TabContent>
                <TabPane tabId={Tabs.Write}>
                  <Input
                    type="textarea"
                    value={contents}
                    onChange={this.onChangeBody}
                    />
                </TabPane>
                <TabPane tabId={Tabs.Preview}>
                  <ErrorBoundary key={contents}>
                    <Card body>
                      <MDXView children={contents} />
                    </Card>
                  </ErrorBoundary>
                </TabPane>
              </TabContent>
            </TabContainer>
          </CardBody>
        </Collapse>
        {expanded && (
          <CardFooter>
            <div className="pull-right">
              <Form inline onSubmit={this.onSubmit}>
                <FormGroup className="mb-2 mr-sm-4 mb-sm-0">
                  <Label
                    check
                    data-tip="Only show to team members of the same project"
                  >
                    <Input type="checkbox" /> Private
                  </Label>
                </FormGroup>
                <Button color="info">Submit</Button>
              </Form>
            </div>
          </CardFooter>
        )}
      </Card>
    );
  }

  private onSubmit = (event: any) => {
    event.preventDefault();
    this.props
      .mutate({
        variables: {
          body: this.state.contents,
          problemId: this.props.problem.id,
          private: false,
        },
      })
      .then((response: ApolloQueryResult<CreateSolution>) => {
        this.setState(prevState => ({
          ...prevState,
          contents: "",
          expanded: false,
        }));
        const solution = response.data.createSolution;
        console.log("New solution", solution);
      })
      .catch(error => console.error(error));
  };

  private onChangeBody = (event: any) => {
    const value = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      contents: value,
    }));
  };

  private toggle = () => {
    this.setState(prevState => ({
      ...prevState,
      expanded: !this.state.expanded,
    }));
  };
}

/*
const components = {
  code: (props: any) => {
    const { className = "" } = props;
    const match = className.match(/language-([a-z]+)/i);
    const language = match && match[1];
    if (language === "diff") {
      return <Diff children={props.children} />;
    }
    return (
      <SyntaxHighlighter
        language={language}
        showLineNumbers={false}
        wrapLines={false}
        children={props.children}
      />
    );
  },
  pre: (props: any) => {
    const isCodeBlock = Boolean(
      props.children &&
        props.children.props &&
        props.children.props.name === "code"
    );
    if (isCodeBlock) {
      return <div>{props.children}</div>;
    }
    return <pre {...props} />;
  },
};

// Provide custom components that will be referenced as JSX
// in the markdown string
const scope = {
  Demo: (props: any) => <div>This is a demo React component</div>,
  HomePath: (props: any) => <span>/home/user/</span>,
};

class ErrorBoundary extends React.PureComponent<
  { children: React.ReactNode },
  { error?: Error }
> {
  public state: { error?: Error } = {
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  public render() {
    const { error } = this.state;
    if (error) {
      return (
        <Alert color="danger">
          <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
          {error && error.message}
        </Alert>
      );
    }
    return this.props.children;
  }
}
*/

export interface SolutionInputInternalProps {
  problem: Problem;
}

export interface SolutionInputState {
  expanded: boolean;
  contents: string;
}

export type SolutionInputProps = MutateProps<any, CreateSolutionVariables> &
  SolutionInputInternalProps;

export const SolutionInput: React.SFC<SolutionInputInternalProps> = compose(
  graphql(createSolutionMutation, {
    options: {
      refetchQueries: ["ProblemSolutionsQuery"],
    },
  })
)(SolutionInputInternal);

enum Tabs {
  Write = "write",
  Preview = "preview",
}
