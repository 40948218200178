import * as React from "react";
import { Button, Form, Input, InputGroup, InputGroupAddon } from "reactstrap";
import { compose, graphql, MutateProps } from "react-apollo";
import { ProjectQuery_project as Project } from "../../../../graphql";

import * as addMemberToProjectMutation from "../../../../graphql/mutations/addMemberToProject.graphql";
import { AddMemberToProjectVariables } from "../../../../graphql";

export class AddUserInternal extends React.Component<
  AddUserProps,
  AddUserState
> {
  state = {
    email: "",
  };

  public render() {
    const { project } = this.props;
    const addMemberToProject = this.props.mutate;
    return (
      <Form
        onSubmit={e => {
          e.preventDefault();
          addMemberToProject({
            variables: { userEmail: this.state.email, projectId: project.id },
          })
            .then(() => {
              this.setState(prevState => ({
                ...prevState,
                email: "",
              }));
            })
            .catch(console.error);
        }}
      >
        <InputGroup>
          <Input
            type="text"
            name="user-email"
            id="user-email"
            placeholder="Enter user email"
            value={this.state.email}
            onChange={this.onChangeEmail}
          />
          <InputGroupAddon addonType="append">
            <Button color="primary">Add member</Button>
          </InputGroupAddon>
        </InputGroup>
      </Form>
    );
  }

  private onChangeEmail = (event: any) => {
    const { value } = event.target;
    this.setState(prevState => ({
      ...prevState,
      email: value,
    }));
  };
}

type AddUserProps = MutateProps<any, AddMemberToProjectVariables> & {
  project: Project;
};

interface AddUserState {
  email: string;
}

export const AddUser = compose(
  graphql(addMemberToProjectMutation, {
    options: {
      refetchQueries: ["ProjectsQuery"],
    },
  })
)(AddUserInternal);
