import * as React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { solarizedDark } from "react-syntax-highlighter/dist/styles/hljs";
import * as classnames from "classnames";

export default (props: SyntaxHighlighterProps) => {
  return (
    <SyntaxHighlighter
      classnames={classnames("syntax-highlighter", props.classNames)}
      style={solarizedDark}
      {...props}
    />
  );
};
