import * as React from "react";

export const TabContext = React.createContext<TabContextState>({
  activeTab: undefined,
  setTab: (newTab: Tab) => {},
});

export interface TabContextState {
  activeTab: Tab;
  setTab(newTab: Tab): void;
}

export type Tab = undefined | string | number;
