import * as React from "react";
import {
  Card,
  CardHeader,
  Alert,
} from "reactstrap";
import TimeAgo from "react-timeago";
import * as moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";

import { ProblemCommitsQuery_problem_commits } from "../../../graphql";
import { parseGraphQLDate } from "../../../utils/parseGraphQLDate";
import { CommitDiff } from "./CommitDiff";

export class Commit extends React.PureComponent<CommitProps, {}> {
  public render() {
    const { commit } = this.props;
    const pushedAt = parseGraphQLDate(commit.pushedAt);
    return (
      <Card key={commit.id}>
        <CardHeader>
          <FontAwesomeIcon icon={faCodeBranch} />{" "}
          <strong>
            <a href={`https://github.com/${commit.author}`} target="_blank">
              {commit.author}
            </a>
          </strong>
          {" committed on "}
          {moment(pushedAt).format(
            "dddd, MMMM Do, YYYY [at] hh:mm:ss A"
          )} (<TimeAgo date={pushedAt} />)
          <br />
          <Alert color="dark">
            <a href={commit.commitUrl} target="_blank">
              {commit.message}
            </a>
          </Alert>
        </CardHeader>
        <CommitDiff commit={commit} />
      </Card>
    );
  }
}

export interface CommitProps {
  commit: ProblemCommitsQuery_problem_commits;
}
