import * as React from "react";
import { NavItem, NavLink } from "reactstrap";
import classnames = require("classnames");

import { TabContext, Tab } from "./TabContext";

export const TabNavItem = ({ tab, children }: TabNavItemProps) => {
  return (
    <TabContext.Consumer>
      {({ activeTab, setTab }) => (
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === tab,
            })}
            onClick={() => {
              setTab(tab);
            }}
          >
            {children}
          </NavLink>
        </NavItem>
      )}
    </TabContext.Consumer>
  );
};

export interface TabNavItemProps {
  tab: Tab;
  children: React.ReactNode;
}
