import * as React from "react";
import * as classnames from "classnames";

import { ReviewStatus } from "../graphql";
import * as fullFix from "../images/review/full-fix.svg";
import * as partialFix from "../images/review/partial-fix.svg";

export const ReviewStatusIcon = ({
  status,
  className,
}: {
  status: ReviewStatus;
  className?: string;
}) => {
  return (
    <img
      className={classnames("review-status-icon", className)}
      src={iconForStatus(status)}
    />
  );
};

function iconForStatus(status: ReviewStatus) {
  switch (status) {
    case ReviewStatus.FullFix: return fullFix;
    case ReviewStatus.PartialFix: return partialFix;
    default: return null;
  }
}
