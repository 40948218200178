import * as React from "react";

import { DateTime } from "../../../../components/DateTime";

export function SolutionTitle({ authorName, updatedAt }: SourceIconProps) {
  return (
    <span>
      <DateTime date={updatedAt} /> by <strong>{authorName}</strong>
    </span>
  );
}

export interface SourceIconProps {
  authorName: string;
  updatedAt: Date | string;
}
