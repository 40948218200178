import * as React from "react";

export class DownloadTextButton extends React.Component<DownloadTextButtonProps> {
  public render() {
    return <a onClick={this.download}>{this.props.children}</a>;
  }

  private download = (): void => {
    const { fileName, text } = this.props;
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", fileName);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };
}

export interface DownloadTextButtonProps {
  fileName: string;
  text: string;
}
