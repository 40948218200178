import * as React from "react";

export const AsciinemaPlayer = ({
  videoId,
  width,
  height,
}: AsciinemaPlayerProps) => {
  return (
    <iframe
      className="asciinema"
      allowFullScreen
      width={`${width}`}
      height={`${height}`}
      src={`https://asciinema.org/a/${videoId}/iframe`}
    />
  );
};

export interface AsciinemaPlayerProps {
  width: number;
  height: number;
  videoId: string;
}
