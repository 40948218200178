import * as React from "react";
import * as _ from "lodash";
import * as stringify from "fast-json-stable-stringify";
import { ListGroup } from "reactstrap";

import {
  ProblemQuery_problem,
  ProblemQuery_problem_stackTraces,
} from "../../../../graphql";
import { StackTrace } from "./StackTrace";

export class StackTraces extends React.Component<StackTracesProps> {
  public render() {
    if (this.stackTraces.length === 0) {
      return <div>No stack traces parsed from log file.</div>;
    }
    return (
      <ListGroup flush>
        {this.stackTraceGroups.map((group, index) => (
          <StackTrace
            key={index}
            stackTrace={group.stackTrace}
            duplicates={group.count - 1}
          />
        ))}
      </ListGroup>
    );
  }

  private get stackTraceGroups() {
    return Object.values(
      _.groupBy(
        this.stackTraces,
        (stackTrace: ProblemQuery_problem_stackTraces) =>
          this.stringifyStackTrace(stackTrace)
      )
    ).map(group => ({
      count: group.length,
      stackTrace: group[0],
    }));
  }

  private stringifyStackTrace(stackTrace: ProblemQuery_problem_stackTraces) {
    return stringify({
      ...stackTrace,
      frames: stackTrace.frames.map(frame =>
        _.pick(frame, ["filePath", "fileName", "callee"])
      ),
    });
  }

  private get stackTraces() {
    return this.props.stackTraces;
  }
}

export interface StackTracesProps {
  stackTraces: ProblemQuery_problem["stackTraces"];
}
