declare const GA_MEASUREMENT_ID: string;
console.log("GA_MEASUREMENT_ID", GA_MEASUREMENT_ID);

declare global {
  interface Window {
    gtag(...args: any[]): void;
  }
}

export function gtag(...args: any[]) {
  console.log("gtag", ...args);
  window.gtag(...args);
}

export function trackEvent(event: {
  action: string;
  category?: string;
  label?: string;
  value?: number;
}): void {
  gtag("event", event.action, {
    event_category: event.category,
    event_label: event.label,
    value: event.value,
  });
}

export function trackPromise(event: {
  name: string;
  category?: string;
  label?: string;
}) {
  return (promise: Promise<any>) => {
    const before = nowMilliseconds();
    return promise
      .then(results => {
        const after = nowMilliseconds();
        const diff = after - before;
        trackTimingComplete({
          ...event,
          value: diff,
        });
        return results;
      })
      .catch((error: Error) => {
        trackError({ error });
        return Promise.reject(error);
      });
  };
}

export function trackTimingComplete(event: {
  name: string;
  value: number;
  category?: string;
  label?: string;
}) {
  gtag("event", "timing_complete", {
    name: event.name,
    value: event.value,
    event_category: event.category,
    event_label: event.label,
  });
}

export function trackError({
  error,
  fatal = false,
}: {
  error: Error;
  fatal?: boolean;
}) {
  const description = error.message;
  gtag("event", "exception", {
    description,
    fatal,
  });
}

function nowMilliseconds(): number {
  // Feature detects Navigation Timing API support.
  if (window.performance) {
    // Gets the number of milliseconds since page load
    // (and rounds the result since the value must be an integer).
    return Math.round(performance.now());
  }
  return Date.now();
}

let lastPage: Page | undefined = undefined;
let lastUserId: string | undefined = undefined;
let currentUserId: string | undefined = undefined;

export function setUserId(userId: string | undefined): void {
  console.log("setUserId", userId);
  currentUserId = userId;
  pageView(lastPage);
  lastUserId = userId;
}

export function pageView(page: Page = getCurrentPage()): void {
  console.log("pageView", page);
  if (lastPage && lastPage.path === page.path && currentUserId === lastUserId) {
    console.log(
      "pageview unchanged",
      page,
      lastPage,
      currentUserId,
      lastUserId
    );
    return;
  }
  setConfig({
    user_id: currentUserId,
    page_title: page.title,
    page_path: page.path,
  });
  lastPage = page;
}

function getCurrentPage(): Page {
  const pagePath = location.pathname + location.search;
  const pageTitle = document.title;
  return {
    path: pagePath,
    title: pageTitle,
  };
}

interface Page {
  title: string;
  path: string;
}

function setConfig(config: Config) {
  gtag("config", GA_MEASUREMENT_ID, config);
}

interface Config {
  user_id?: string;
  page_title?: string;
  page_path?: string;
}
