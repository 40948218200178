import * as React from "react";
import { AuthContainer } from "./AuthContainer";
import { Row, Col } from "reactstrap";

import * as appPreview from "../images/landing/app-demo.svg";
import * as cliPreview from "../images/landing/cli-demo.svg";
import * as rightArrow from "../images/landing/right-arrow.svg";

export const UnauthenticatedLandingPage = ({
  auth,
}: {
  auth: AuthContainer;
}) => {
  return (
    <div className="landing text-center">
      <div className="container-fluid">
        <div className="jumbotron">
          <h1 className="display-4">
            Welcome to CodePass{" "}
            <span className="badge badge-dark">Alpha</span>
          </h1>
          <p className="lead">
            The Quickest Way To Solve Your Coding Errors
          </p>
          <div className="demo">
            <Row>
              <Col xs="5">
                <img className="cli" src={cliPreview} />
              </Col>
              <Col xs="2">
                <img className="arrow" src={rightArrow} />
                <button className="btn btn-primary btn-lg" onClick={auth.login}>
                  Get Started
                </button>
              </Col>
              <Col xs="5">
                <img className="app" src={appPreview} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};
