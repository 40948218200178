import * as React from "react";
import { Alert, Button } from "reactstrap";
import { Query, QueryResult } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";

import * as problemsWithFilterQuery from "../../graphql/queries/problemsWithFilter.graphql";
import {
  ProblemsQuery_viewer_user_problems,
  ProblemsFilter,
  ProblemsWithFilterQuery,
  ProblemsWithFilterQueryVariables,
} from "../../graphql";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import {
  ProblemsHeatmapContainer,
  ProblemHeatDay,
} from "../../containers/ProblemsHeatmap";
import { Problem } from "./Problem";
import { DateTime } from "../../components/DateTime";
import { ProblemSearchInput } from "./ProblemSearchInput";

export default class History extends React.Component<
  HistoryProps,
  HistoryState
> {
  public state: HistoryState = {
    selectedDay: undefined,
    filter: {
      message: "",
      projectIds: [],
      userIds: [],
    },
  };

  public render() {
    return (
      <div>
        <Query query={problemsWithFilterQuery} variables={this.queryVariables}>
          {(queryResult: QueryResult<ProblemsWithFilterQuery>) => {
            const { loading, error, data } = queryResult;
            if (loading) {
              return (
                  <LoadingSpinner tall />
              );
            }
            if (error || !data) {
              console.error(error);
              return (
                <Alert color="danger">
                  <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
                  {error && error.message}
                </Alert>
              );
            }
            const { viewer } = data;
            if (!viewer) {
              console.log("No viewer");
              return <LoadingSpinner tall />;
            }
            const { user } = viewer;
            if (!user) {
              console.log("No user");
              return <LoadingSpinner tall />;
            }
            const problems = data.problems.edges.map(edge => edge.node);
            const { selectedDay } = this.state;
            return (
              <div>
                {this.renderHeader(selectedDay)}
                <ProblemsHeatmapContainer
                  userId={user.id}
                  projectId={this.props.projectId}
                  selected={selectedDay}
                  onClick={value => this.onSelectDay(value)}
                />
                <ProblemSearchInput
                  disableProjectSelect={!!this.props.projectId}
                  filter={this.state.filter}
                  changeFilter={this.changeFilter}
                />
                <br />
                {this.renderRecent(problems)}
              </div>
            );
          }}
        </Query>
      </div>
    );
  }

  private renderHeader(selected: ProblemHeatDay | undefined) {
    if (!selected) {
      return <h1>Recent</h1>;
    }
    const { date } = selected;
    return (
      <h1>
        <DateTime date={date} />{" "}
        <Button color="success" onClick={this.clearSelection}>
          Clear
        </Button>
      </h1>
    );
  }

  private renderRecent(problems: ProblemsQuery_viewer_user_problems[]) {
    return <div>{this.renderProblems(problems)}</div>;
  }

  private get queryVariables(): ProblemsWithFilterQueryVariables {
    const filter: ProblemsFilter = {
      ...this.state.filter,
    };
    const { userId, projectId } = this.props;
    if (userId) {
      filter.userIds = [userId];
    }
    if (projectId) {
      filter.projectIds = [projectId];
    }
    const { selectedDay } = this.state;
    if (selectedDay) {
      const between: ProblemsFilter["between"] = {
        start: moment(selectedDay.date)
          .subtract(0, "days")
          .hours(0)
          .minutes(0)
          .seconds(0),
        end: moment(selectedDay.date)
          .add(1, "days")
          .hours(0)
          .minutes(0)
          .seconds(0),
      };
      filter.between = between;
    }
    return {
      filter,
    };
  }

  private clearSelection = () => {
    this.setState(prevState => ({
      ...prevState,
      selectedDay: undefined,
    }));
  };

  private onSelectDay = (value: ProblemHeatDay) => {
    const { selectedDay: selected } = this.state;
    const newSelection =
      selected && value.date.getTime() === selected.date.getTime()
        ? undefined
        : value;
    // console.log('newSelection', value, selected, newSelection);
    this.setState(prevState => ({
      ...prevState,
      selectedDay: newSelection,
    }));
  };

  private changeFilter = (newFilter: ProblemsFilter) => {
    console.log("changeFilter", this.state.filter, newFilter);
    this.setState(prevState => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        ...newFilter,
      },
    }));
  };

  private renderProblems(problems: ProblemsQuery_viewer_user_problems[]) {
    if (problems.length === 0) {
      return <div>No problems matching this filter.</div>;
    }
    return (
      <div>
        {problems.map(problem => {
          return <Problem key={problem.id} problem={problem} />;
        })}
      </div>
    );
  }
}

interface HistoryProps {
  projectId?: string;
  userId?: string;
}

interface HistoryState {
  selectedDay?: ProblemHeatDay;
  filter: ProblemsFilter;
}
