import * as React from "react";
import { Alert } from "reactstrap";
import * as _ from "lodash";

import { Query, QueryResult } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import * as historyHeatmapByDayQuery from "../graphql/queries/historyHeatmapByDay.graphql";
import {
  HistoryHeatmapByDayQuery,
  HistoryHeatmapByDayQuery_historyHeatmap_byDay,
} from "../graphql";
import { LoadingSpinner } from "../components/LoadingSpinner";

import { Sparklines, SparklinesCurve } from "react-sparklines";
import { ProblemHeatDay } from "./ProblemsHeatmap";
import moment = require("moment");

export class ProblemsSparkline extends React.Component<
  ProblemsSparklineProps,
  {}
> {
  private numDays = 30;

  render() {
    return (
      <Query
        query={historyHeatmapByDayQuery}
        variables={{
          userId: this.props.userId,
          projectId: this.props.projectId,
        }}
      >
        {(queryResult: QueryResult<HistoryHeatmapByDayQuery>) => {
          const { loading, error, data } = queryResult;
          if (loading) {
            return <LoadingSpinner />;
          }
          if (error || !data) {
            console.error(error);
            return (
              <Alert color="danger">
                <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
                {error && error.message}
              </Alert>
            );
          }

          const scale = 20;
          const height = 2;
          const valuesByDay = data.historyHeatmap.byDay;
          if (valuesByDay.length === 0) {
            return <div className="lead">No historical data found.</div>;
          }
          const sparklineValues = this.values(valuesByDay);
          return (
            <Sparklines
              data={sparklineValues}
              preserveAspectRatio="xMidYMax meet"
              width={this.numDays * scale}
              height={height * scale}
              min={0}
              limit={this.numDays}
            >
              <SparklinesCurve
                style={
                  {
                    stroke: "#41c3f9",
                    fill: "#41c3f9",
                    fillOpacity: "0.25",
                  } as any
                }
              />
            </Sparklines>
          );
        }}
      </Query>
    );
  }

  private values(
    data: HistoryHeatmapByDayQuery_historyHeatmap_byDay[]
  ): number[] {
    const heatmapValues: ProblemHeatDay[] = data.map(item => ({
      count: item.count,
      date: moment([item.year, item.month - 1, item.day]).toDate(),
    }));
    const values = this.fillValues(heatmapValues);
    return _.orderBy(values, ["date", "desc"])
      .map(value => value.count)
      .slice(0, this.numDays);
  }

  private fillValues(sparseValues: ProblemHeatDay[]): ProblemHeatDay[] {
    const today = this.today;
    const valueMap: { [key: number]: number } = sparseValues.reduce(
      (valueMap, val: ProblemHeatDay) => ({
        ...valueMap,
        [val.date.getTime()]: val.count,
      }),
      {}
    );
    const values: ProblemHeatDay[] = getRange(this.numDays).map(
      (index: number): ProblemHeatDay => {
        const currDate = shiftDate(today, -index);
        const count = valueMap[currDate.getTime()];
        return {
          date: currDate,
          count: isNaN(count) ? 0 : count,
        };
      }
    );
    return values;
  }

  private get today(): Date {
    const today = new Date();
    const todayRounded = moment([
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
    ]).toDate();
    return todayRounded;
  }
}

export interface ProblemsSparklineProps {
  projectId?: string;
  userId?: string;
}

function getRange(count: number): number[] {
  return Array.from({ length: count }, (_, i) => i);
}

function shiftDate(date: Date, numDays: number) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}
