import * as React from "react";
import { Alert } from "reactstrap";
import { Query, QueryResult } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import * as viewerQuery from "../graphql/queries/viewer.graphql";
import { ViewerQuery } from "../graphql";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { AsciinemaPlayer } from "../components/AsciinemaPlayer";
import { parseGraphQLDate } from "../utils/parseGraphQLDate";

export default function Account() {
  return (
    <div>
      <Query query={viewerQuery} variables={{}}>
        {(queryResult: QueryResult<ViewerQuery>) => {
          const { loading, error, data } = queryResult;
          if (loading) {
            return (
              <LoadingSpinner />
            );
          }
          if (error || !data) {
            console.error(error);
            return (
              <Alert color="danger">
                <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
                {error && error.message}
              </Alert>
            );
          }
          const { viewer } = data;
          if (!viewer) {
            console.log('No viewer');
            return (
              <LoadingSpinner />
            );
          }
          const { user } = viewer;
          if (!user) {
            console.log('No user');
            return (
              <LoadingSpinner />
            );
          }
          return (
            <div>
              <h1>{user.name}</h1>
              <div>
                <strong>Email:</strong> <kbd>{user.email}</kbd>
              </div>
              <div>
                <strong>Upload token:</strong> <kbd>{user.uploadToken}</kbd>
              </div>
              <div>
                <strong>Created date:</strong>{" "}
                {parseGraphQLDate(user.createdAt).toDateString()}
              </div>
              <hr />
              <h2>Login with CLI</h2>
              <AsciinemaPlayer width={691} height={524} videoId="221477" />
            </div>
          );
        }}
      </Query>
    </div>
  );
}
