/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddMemberToProject
// ====================================================

export interface AddMemberToProject_addMemberToProject_owner {
  id: string;
  name: string;
}

export interface AddMemberToProject_addMemberToProject_members {
  id: string;
  name: string;
}

export interface AddMemberToProject_addMemberToProject {
  id: string;
  name: string;
  createdAt: string;
  owner: AddMemberToProject_addMemberToProject_owner;
  members: AddMemberToProject_addMemberToProject_members[];
}

export interface AddMemberToProject {
  addMemberToProject: AddMemberToProject_addMemberToProject;
}

export interface AddMemberToProjectVariables {
  userEmail: string;
  projectId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateProject
// ====================================================

export interface CreateProject_createProject {
  id: string;
  name: string;
}

export interface CreateProject {
  createProject: CreateProject_createProject;
}

export interface CreateProjectVariables {
  ownerId: string;
  name: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateReview
// ====================================================

export interface CreateReview_createReview_author {
  id: string;
  name: string;
}

export interface CreateReview_createReview_problem {
  id: string;
}

export interface CreateReview_createReview_project {
  id: string;
}

export interface CreateReview_createReview {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: CreateReview_createReview_author;
  problem: CreateReview_createReview_problem;
  project: CreateReview_createReview_project;
  createdAt: string;
  updatedAt: string;
}

export interface CreateReview {
  createReview: CreateReview_createReview;
}

export interface CreateReviewVariables {
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  problemId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSolution
// ====================================================

export interface CreateSolution_createSolution_SolutionExternal_reactions {
  totalCount: number;
  thumbsUp: number;
  thumbsDown: number;
  laugh: number;
  hooray: number;
  confused: number;
  heart: number;
}

export interface CreateSolution_createSolution_SolutionExternal_parent {
  id: string;
  source: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  htmlUrl: string;
  body: string;
}

export interface CreateSolution_createSolution_SolutionExternal_reviews_author {
  id: string;
  name: string;
}

export interface CreateSolution_createSolution_SolutionExternal_reviews_problem {
  id: string;
}

export interface CreateSolution_createSolution_SolutionExternal_reviews_project {
  id: string;
}

export interface CreateSolution_createSolution_SolutionExternal_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: CreateSolution_createSolution_SolutionExternal_reviews_author;
  problem: CreateSolution_createSolution_SolutionExternal_reviews_problem;
  project: CreateSolution_createSolution_SolutionExternal_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface CreateSolution_createSolution_SolutionExternal {
  id: string;
  source: any;
  body: string;
  htmlUrl: string;
  authorName: string;
  createdAt: any;
  updatedAt: any;
  reactions: CreateSolution_createSolution_SolutionExternal_reactions;
  parent: CreateSolution_createSolution_SolutionExternal_parent | null;
  reviews: CreateSolution_createSolution_SolutionExternal_reviews[];
}

export interface CreateSolution_createSolution_SolutionInternal_author {
  id: string;
  name: string;
}

export interface CreateSolution_createSolution_SolutionInternal_reviews_author {
  id: string;
  name: string;
}

export interface CreateSolution_createSolution_SolutionInternal_reviews_problem {
  id: string;
}

export interface CreateSolution_createSolution_SolutionInternal_reviews_project {
  id: string;
}

export interface CreateSolution_createSolution_SolutionInternal_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: CreateSolution_createSolution_SolutionInternal_reviews_author;
  problem: CreateSolution_createSolution_SolutionInternal_reviews_problem;
  project: CreateSolution_createSolution_SolutionInternal_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface CreateSolution_createSolution_SolutionInternal {
  id: string;
  source: any;
  body: string;
  author: CreateSolution_createSolution_SolutionInternal_author;
  createdAt: any;
  updatedAt: any;
  reviews: CreateSolution_createSolution_SolutionInternal_reviews[];
}

export type CreateSolution_createSolution = CreateSolution_createSolution_SolutionExternal | CreateSolution_createSolution_SolutionInternal;

export interface CreateSolution {
  createSolution: CreateSolution_createSolution;
}

export interface CreateSolutionVariables {
  body: string;
  private: boolean;
  problemId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveMemberFromProject
// ====================================================

export interface RemoveMemberFromProject_removeMemberFromProject_owner {
  id: string;
  name: string;
}

export interface RemoveMemberFromProject_removeMemberFromProject_members {
  id: string;
  name: string;
}

export interface RemoveMemberFromProject_removeMemberFromProject {
  id: string;
  name: string;
  createdAt: string;
  owner: RemoveMemberFromProject_removeMemberFromProject_owner;
  members: RemoveMemberFromProject_removeMemberFromProject_members[];
}

export interface RemoveMemberFromProject {
  removeMemberFromProject: RemoveMemberFromProject_removeMemberFromProject;
}

export interface RemoveMemberFromProjectVariables {
  userId: string;
  projectId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HistoryHeatmapByDayQuery
// ====================================================

export interface HistoryHeatmapByDayQuery_historyHeatmap_byDay {
  count: number;
  year: number;
  month: number;
  day: number;
}

export interface HistoryHeatmapByDayQuery_historyHeatmap {
  byDay: HistoryHeatmapByDayQuery_historyHeatmap_byDay[];
}

export interface HistoryHeatmapByDayQuery {
  historyHeatmap: HistoryHeatmapByDayQuery_historyHeatmap;
}

export interface HistoryHeatmapByDayQueryVariables {
  userId?: string | null;
  projectId?: string | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProblemQuery
// ====================================================

export interface ProblemQuery_problem_stackTraces_frames_package {
  name: string | null;
  version: string | null;
  filePath: string | null;
}

export interface ProblemQuery_problem_stackTraces_frames_info {
  docs: string | null;
  code: string | null;
}

export interface ProblemQuery_problem_stackTraces_frames {
  filePath: string | null;
  fileName: string | null;
  callee: string | null;
  column: number | null;
  line: number | null;
  package: ProblemQuery_problem_stackTraces_frames_package | null;
  info: ProblemQuery_problem_stackTraces_frames_info | null;
}

export interface ProblemQuery_problem_stackTraces {
  kind: StackTraceKind;
  message: string;
  frames: ProblemQuery_problem_stackTraces_frames[];
}

export interface ProblemQuery_problem_user {
  id: string;
  name: string;
  email: string;
}

export interface ProblemQuery_problem_project {
  id: string;
  name: string;
}

export interface ProblemQuery_problem_similar_stackTraces_frames_package {
  name: string | null;
  version: string | null;
  filePath: string | null;
}

export interface ProblemQuery_problem_similar_stackTraces_frames {
  filePath: string | null;
  fileName: string | null;
  callee: string | null;
  column: number | null;
  line: number | null;
  package: ProblemQuery_problem_similar_stackTraces_frames_package | null;
}

export interface ProblemQuery_problem_similar_stackTraces {
  kind: StackTraceKind;
  message: string;
  frames: ProblemQuery_problem_similar_stackTraces_frames[];
}

export interface ProblemQuery_problem_similar_project {
  id: string;
  name: string;
}

export interface ProblemQuery_problem_similar_user {
  id: string;
  name: string;
}

export interface ProblemQuery_problem_similar_reviews_author {
  id: string;
  name: string;
}

export interface ProblemQuery_problem_similar_reviews_problem {
  id: string;
}

export interface ProblemQuery_problem_similar_reviews_project {
  id: string;
}

export interface ProblemQuery_problem_similar_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: ProblemQuery_problem_similar_reviews_author;
  problem: ProblemQuery_problem_similar_reviews_problem;
  project: ProblemQuery_problem_similar_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface ProblemQuery_problem_similar {
  id: string;
  stackTraces: ProblemQuery_problem_similar_stackTraces[];
  createdAt: any;
  project: ProblemQuery_problem_similar_project;
  user: ProblemQuery_problem_similar_user;
  reviews: ProblemQuery_problem_similar_reviews[];
}

export interface ProblemQuery_problem_environment_user {
  name: string;
  homeDir: string;
}

export interface ProblemQuery_problem_environment_variables {
  name: string;
  value: string | null;
  isEmpty: boolean;
  isHidden: boolean;
}

export interface ProblemQuery_problem_environment_executables {
  name: string;
  version: string;
}

export interface ProblemQuery_problem_environment {
  user: ProblemQuery_problem_environment_user | null;
  variables: ProblemQuery_problem_environment_variables[];
  executables: ProblemQuery_problem_environment_executables[];
}

export interface ProblemQuery_problem {
  id: string;
  logs: string | null;
  stackTraces: ProblemQuery_problem_stackTraces[];
  createdAt: any;
  user: ProblemQuery_problem_user;
  project: ProblemQuery_problem_project;
  similar: ProblemQuery_problem_similar[];
  environment: ProblemQuery_problem_environment;
}

export interface ProblemQuery {
  problem: ProblemQuery_problem;
}

export interface ProblemQueryVariables {
  projectId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProblemCommitsQuery
// ====================================================

export interface ProblemCommitsQuery_problem_commits_files {
  sha: string;
  filename: string;
  blob_url: string;
  patch: string;
}

export interface ProblemCommitsQuery_problem_commits {
  id: string;
  author: string;
  message: string;
  commitUrl: string;
  diffUrl: string;
  pushedAt: string;
  files: ProblemCommitsQuery_problem_commits_files[];
}

export interface ProblemCommitsQuery_problem {
  id: string;
  commits: ProblemCommitsQuery_problem_commits[];
}

export interface ProblemCommitsQuery {
  problem: ProblemCommitsQuery_problem;
}

export interface ProblemCommitsQueryVariables {
  projectId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProblemDependenciesQuery
// ====================================================

export interface ProblemDependenciesQuery_problem_dependenciesList {
  name: string;
  version: string;
  subs: boolean;
}

export interface ProblemDependenciesQuery_problem {
  id: string;
  dependenciesList: ProblemDependenciesQuery_problem_dependenciesList[];
}

export interface ProblemDependenciesQuery {
  problem: ProblemDependenciesQuery_problem;
}

export interface ProblemDependenciesQueryVariables {
  projectId: string;
  parents: string[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProblemsQuery
// ====================================================

export interface ProblemsQuery_viewer_user_problems_stackTraces_frames_package {
  name: string | null;
  version: string | null;
  filePath: string | null;
}

export interface ProblemsQuery_viewer_user_problems_stackTraces_frames {
  filePath: string | null;
  fileName: string | null;
  callee: string | null;
  column: number | null;
  line: number | null;
  package: ProblemsQuery_viewer_user_problems_stackTraces_frames_package | null;
}

export interface ProblemsQuery_viewer_user_problems_stackTraces {
  kind: StackTraceKind;
  message: string;
  frames: ProblemsQuery_viewer_user_problems_stackTraces_frames[];
}

export interface ProblemsQuery_viewer_user_problems_project {
  id: string;
  name: string;
}

export interface ProblemsQuery_viewer_user_problems_user {
  id: string;
  name: string;
}

export interface ProblemsQuery_viewer_user_problems_reviews_author {
  id: string;
  name: string;
}

export interface ProblemsQuery_viewer_user_problems_reviews_problem {
  id: string;
}

export interface ProblemsQuery_viewer_user_problems_reviews_project {
  id: string;
}

export interface ProblemsQuery_viewer_user_problems_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: ProblemsQuery_viewer_user_problems_reviews_author;
  problem: ProblemsQuery_viewer_user_problems_reviews_problem;
  project: ProblemsQuery_viewer_user_problems_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface ProblemsQuery_viewer_user_problems {
  id: string;
  stackTraces: ProblemsQuery_viewer_user_problems_stackTraces[];
  createdAt: any;
  project: ProblemsQuery_viewer_user_problems_project;
  user: ProblemsQuery_viewer_user_problems_user;
  reviews: ProblemsQuery_viewer_user_problems_reviews[];
}

export interface ProblemsQuery_viewer_user {
  id: string;
  problems: ProblemsQuery_viewer_user_problems[];
}

export interface ProblemsQuery_viewer {
  user: ProblemsQuery_viewer_user;
}

export interface ProblemsQuery {
  viewer: ProblemsQuery_viewer | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProblemsForDayQuery
// ====================================================

export interface ProblemsForDayQuery_viewer_user_problemsForDay_stackTraces_frames_package {
  name: string | null;
  version: string | null;
  filePath: string | null;
}

export interface ProblemsForDayQuery_viewer_user_problemsForDay_stackTraces_frames {
  filePath: string | null;
  fileName: string | null;
  callee: string | null;
  column: number | null;
  line: number | null;
  package: ProblemsForDayQuery_viewer_user_problemsForDay_stackTraces_frames_package | null;
}

export interface ProblemsForDayQuery_viewer_user_problemsForDay_stackTraces {
  kind: StackTraceKind;
  message: string;
  frames: ProblemsForDayQuery_viewer_user_problemsForDay_stackTraces_frames[];
}

export interface ProblemsForDayQuery_viewer_user_problemsForDay_project {
  id: string;
  name: string;
}

export interface ProblemsForDayQuery_viewer_user_problemsForDay_user {
  id: string;
  name: string;
}

export interface ProblemsForDayQuery_viewer_user_problemsForDay_reviews_author {
  id: string;
  name: string;
}

export interface ProblemsForDayQuery_viewer_user_problemsForDay_reviews_problem {
  id: string;
}

export interface ProblemsForDayQuery_viewer_user_problemsForDay_reviews_project {
  id: string;
}

export interface ProblemsForDayQuery_viewer_user_problemsForDay_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: ProblemsForDayQuery_viewer_user_problemsForDay_reviews_author;
  problem: ProblemsForDayQuery_viewer_user_problemsForDay_reviews_problem;
  project: ProblemsForDayQuery_viewer_user_problemsForDay_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface ProblemsForDayQuery_viewer_user_problemsForDay {
  id: string;
  stackTraces: ProblemsForDayQuery_viewer_user_problemsForDay_stackTraces[];
  createdAt: any;
  project: ProblemsForDayQuery_viewer_user_problemsForDay_project;
  user: ProblemsForDayQuery_viewer_user_problemsForDay_user;
  reviews: ProblemsForDayQuery_viewer_user_problemsForDay_reviews[];
}

export interface ProblemsForDayQuery_viewer_user {
  id: string;
  problemsForDay: ProblemsForDayQuery_viewer_user_problemsForDay[];
}

export interface ProblemsForDayQuery_viewer {
  user: ProblemsForDayQuery_viewer_user;
}

export interface ProblemsForDayQuery {
  viewer: ProblemsForDayQuery_viewer | null;
}

export interface ProblemsForDayQueryVariables {
  year: number;
  month: number;
  day: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProblemSolutionsQuery
// ====================================================

export interface ProblemSolutionsQuery_problem_solutions_SolutionExternal_reactions {
  totalCount: number;
  thumbsUp: number;
  thumbsDown: number;
  laugh: number;
  hooray: number;
  confused: number;
  heart: number;
}

export interface ProblemSolutionsQuery_problem_solutions_SolutionExternal_parent {
  id: string;
  source: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  htmlUrl: string;
  body: string;
}

export interface ProblemSolutionsQuery_problem_solutions_SolutionExternal_reviews_author {
  id: string;
  name: string;
}

export interface ProblemSolutionsQuery_problem_solutions_SolutionExternal_reviews_problem {
  id: string;
}

export interface ProblemSolutionsQuery_problem_solutions_SolutionExternal_reviews_project {
  id: string;
}

export interface ProblemSolutionsQuery_problem_solutions_SolutionExternal_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: ProblemSolutionsQuery_problem_solutions_SolutionExternal_reviews_author;
  problem: ProblemSolutionsQuery_problem_solutions_SolutionExternal_reviews_problem;
  project: ProblemSolutionsQuery_problem_solutions_SolutionExternal_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface ProblemSolutionsQuery_problem_solutions_SolutionExternal {
  id: string;
  source: any;
  body: string;
  htmlUrl: string;
  authorName: string;
  createdAt: any;
  updatedAt: any;
  reactions: ProblemSolutionsQuery_problem_solutions_SolutionExternal_reactions;
  parent: ProblemSolutionsQuery_problem_solutions_SolutionExternal_parent | null;
  reviews: ProblemSolutionsQuery_problem_solutions_SolutionExternal_reviews[];
}

export interface ProblemSolutionsQuery_problem_solutions_SolutionInternal_author {
  id: string;
  name: string;
}

export interface ProblemSolutionsQuery_problem_solutions_SolutionInternal_reviews_author {
  id: string;
  name: string;
}

export interface ProblemSolutionsQuery_problem_solutions_SolutionInternal_reviews_problem {
  id: string;
}

export interface ProblemSolutionsQuery_problem_solutions_SolutionInternal_reviews_project {
  id: string;
}

export interface ProblemSolutionsQuery_problem_solutions_SolutionInternal_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: ProblemSolutionsQuery_problem_solutions_SolutionInternal_reviews_author;
  problem: ProblemSolutionsQuery_problem_solutions_SolutionInternal_reviews_problem;
  project: ProblemSolutionsQuery_problem_solutions_SolutionInternal_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface ProblemSolutionsQuery_problem_solutions_SolutionInternal {
  id: string;
  source: any;
  body: string;
  author: ProblemSolutionsQuery_problem_solutions_SolutionInternal_author;
  createdAt: any;
  updatedAt: any;
  reviews: ProblemSolutionsQuery_problem_solutions_SolutionInternal_reviews[];
}

export type ProblemSolutionsQuery_problem_solutions = ProblemSolutionsQuery_problem_solutions_SolutionExternal | ProblemSolutionsQuery_problem_solutions_SolutionInternal;

export interface ProblemSolutionsQuery_problem {
  id: string;
  solutions: ProblemSolutionsQuery_problem_solutions[] | null;
}

export interface ProblemSolutionsQuery {
  problem: ProblemSolutionsQuery_problem;
}

export interface ProblemSolutionsQueryVariables {
  projectId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProblemsWithFilterQuery
// ====================================================

export interface ProblemsWithFilterQuery_viewer_user {
  id: string;
}

export interface ProblemsWithFilterQuery_viewer {
  user: ProblemsWithFilterQuery_viewer_user;
}

export interface ProblemsWithFilterQuery_problems_edges_node_stackTraces_frames_package {
  name: string | null;
  version: string | null;
  filePath: string | null;
}

export interface ProblemsWithFilterQuery_problems_edges_node_stackTraces_frames {
  filePath: string | null;
  fileName: string | null;
  callee: string | null;
  column: number | null;
  line: number | null;
  package: ProblemsWithFilterQuery_problems_edges_node_stackTraces_frames_package | null;
}

export interface ProblemsWithFilterQuery_problems_edges_node_stackTraces {
  kind: StackTraceKind;
  message: string;
  frames: ProblemsWithFilterQuery_problems_edges_node_stackTraces_frames[];
}

export interface ProblemsWithFilterQuery_problems_edges_node_project {
  id: string;
  name: string;
}

export interface ProblemsWithFilterQuery_problems_edges_node_user {
  id: string;
  name: string;
}

export interface ProblemsWithFilterQuery_problems_edges_node_reviews_author {
  id: string;
  name: string;
}

export interface ProblemsWithFilterQuery_problems_edges_node_reviews_problem {
  id: string;
}

export interface ProblemsWithFilterQuery_problems_edges_node_reviews_project {
  id: string;
}

export interface ProblemsWithFilterQuery_problems_edges_node_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: ProblemsWithFilterQuery_problems_edges_node_reviews_author;
  problem: ProblemsWithFilterQuery_problems_edges_node_reviews_problem;
  project: ProblemsWithFilterQuery_problems_edges_node_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface ProblemsWithFilterQuery_problems_edges_node {
  id: string;
  stackTraces: ProblemsWithFilterQuery_problems_edges_node_stackTraces[];
  createdAt: any;
  project: ProblemsWithFilterQuery_problems_edges_node_project;
  user: ProblemsWithFilterQuery_problems_edges_node_user;
  reviews: ProblemsWithFilterQuery_problems_edges_node_reviews[];
}

export interface ProblemsWithFilterQuery_problems_edges {
  node: ProblemsWithFilterQuery_problems_edges_node;
}

export interface ProblemsWithFilterQuery_problems {
  edges: ProblemsWithFilterQuery_problems_edges[];
}

export interface ProblemsWithFilterQuery {
  viewer: ProblemsWithFilterQuery_viewer | null;
  problems: ProblemsWithFilterQuery_problems;
}

export interface ProblemsWithFilterQueryVariables {
  filter: ProblemsFilter;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectQuery
// ====================================================

export interface ProjectQuery_project_owner {
  id: string;
  name: string;
  email: string;
  uploadToken: string;
  createdAt: string;
  updatedAt: string;
}

export interface ProjectQuery_project_members {
  id: string;
  name: string;
  email: string;
  uploadToken: string;
  createdAt: string;
  updatedAt: string;
}

export interface ProjectQuery_project {
  id: string;
  name: string;
  owner: ProjectQuery_project_owner;
  members: ProjectQuery_project_members[];
  createdAt: string;
}

export interface ProjectQuery {
  project: ProjectQuery_project;
}

export interface ProjectQueryVariables {
  projectId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectsQuery
// ====================================================

export interface ProjectsQuery_viewer_user_projects {
  id: string;
  name: string;
}

export interface ProjectsQuery_viewer_user {
  id: string;
  projects: ProjectsQuery_viewer_user_projects[];
}

export interface ProjectsQuery_viewer {
  user: ProjectsQuery_viewer_user;
}

export interface ProjectsQuery {
  viewer: ProjectsQuery_viewer | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SolutionsQuery
// ====================================================

export interface SolutionsQuery_solutions_SolutionExternal_reactions {
  totalCount: number;
  thumbsUp: number;
  thumbsDown: number;
  laugh: number;
  hooray: number;
  confused: number;
  heart: number;
}

export interface SolutionsQuery_solutions_SolutionExternal_parent {
  id: string;
  source: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  htmlUrl: string;
  body: string;
}

export interface SolutionsQuery_solutions_SolutionExternal_reviews_author {
  id: string;
  name: string;
}

export interface SolutionsQuery_solutions_SolutionExternal_reviews_problem {
  id: string;
}

export interface SolutionsQuery_solutions_SolutionExternal_reviews_project {
  id: string;
}

export interface SolutionsQuery_solutions_SolutionExternal_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: SolutionsQuery_solutions_SolutionExternal_reviews_author;
  problem: SolutionsQuery_solutions_SolutionExternal_reviews_problem;
  project: SolutionsQuery_solutions_SolutionExternal_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface SolutionsQuery_solutions_SolutionExternal {
  id: string;
  source: any;
  body: string;
  htmlUrl: string;
  authorName: string;
  createdAt: any;
  updatedAt: any;
  reactions: SolutionsQuery_solutions_SolutionExternal_reactions;
  parent: SolutionsQuery_solutions_SolutionExternal_parent | null;
  reviews: SolutionsQuery_solutions_SolutionExternal_reviews[];
}

export interface SolutionsQuery_solutions_SolutionInternal_author {
  id: string;
  name: string;
}

export interface SolutionsQuery_solutions_SolutionInternal_reviews_author {
  id: string;
  name: string;
}

export interface SolutionsQuery_solutions_SolutionInternal_reviews_problem {
  id: string;
}

export interface SolutionsQuery_solutions_SolutionInternal_reviews_project {
  id: string;
}

export interface SolutionsQuery_solutions_SolutionInternal_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: SolutionsQuery_solutions_SolutionInternal_reviews_author;
  problem: SolutionsQuery_solutions_SolutionInternal_reviews_problem;
  project: SolutionsQuery_solutions_SolutionInternal_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface SolutionsQuery_solutions_SolutionInternal {
  id: string;
  source: any;
  body: string;
  author: SolutionsQuery_solutions_SolutionInternal_author;
  createdAt: any;
  updatedAt: any;
  reviews: SolutionsQuery_solutions_SolutionInternal_reviews[];
}

export type SolutionsQuery_solutions = SolutionsQuery_solutions_SolutionExternal | SolutionsQuery_solutions_SolutionInternal;

export interface SolutionsQuery {
  solutions: SolutionsQuery_solutions[];
}

export interface SolutionsQueryVariables {
  rawQuery: string;
  dependencies: Dependency[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ViewerQuery
// ====================================================

export interface ViewerQuery_viewer_user {
  id: string;
  name: string;
  email: string;
  uploadToken: string;
  createdAt: string;
  updatedAt: string;
}

export interface ViewerQuery_viewer {
  name: string;
  email: string;
  sub: string;
  user: ViewerQuery_viewer_user;
}

export interface ViewerQuery {
  viewer: ViewerQuery_viewer | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HistoryProblemFields
// ====================================================

export interface HistoryProblemFields_stackTraces_frames_package {
  name: string | null;
  version: string | null;
  filePath: string | null;
}

export interface HistoryProblemFields_stackTraces_frames {
  filePath: string | null;
  fileName: string | null;
  callee: string | null;
  column: number | null;
  line: number | null;
  package: HistoryProblemFields_stackTraces_frames_package | null;
}

export interface HistoryProblemFields_stackTraces {
  kind: StackTraceKind;
  message: string;
  frames: HistoryProblemFields_stackTraces_frames[];
}

export interface HistoryProblemFields_project {
  id: string;
  name: string;
}

export interface HistoryProblemFields_user {
  id: string;
  name: string;
}

export interface HistoryProblemFields_reviews_author {
  id: string;
  name: string;
}

export interface HistoryProblemFields_reviews_problem {
  id: string;
}

export interface HistoryProblemFields_reviews_project {
  id: string;
}

export interface HistoryProblemFields_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: HistoryProblemFields_reviews_author;
  problem: HistoryProblemFields_reviews_problem;
  project: HistoryProblemFields_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface HistoryProblemFields {
  id: string;
  stackTraces: HistoryProblemFields_stackTraces[];
  createdAt: any;
  project: HistoryProblemFields_project;
  user: HistoryProblemFields_user;
  reviews: HistoryProblemFields_reviews[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReactionsFields
// ====================================================

export interface ReactionsFields {
  totalCount: number;
  thumbsUp: number;
  thumbsDown: number;
  laugh: number;
  hooray: number;
  confused: number;
  heart: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReviewFields
// ====================================================

export interface ReviewFields_author {
  id: string;
  name: string;
}

export interface ReviewFields_problem {
  id: string;
}

export interface ReviewFields_project {
  id: string;
}

export interface ReviewFields {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: ReviewFields_author;
  problem: ReviewFields_problem;
  project: ReviewFields_project;
  createdAt: string;
  updatedAt: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SolutionFields
// ====================================================

export interface SolutionFields_SolutionExternal_reactions {
  totalCount: number;
  thumbsUp: number;
  thumbsDown: number;
  laugh: number;
  hooray: number;
  confused: number;
  heart: number;
}

export interface SolutionFields_SolutionExternal_parent {
  id: string;
  source: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  htmlUrl: string;
  body: string;
}

export interface SolutionFields_SolutionExternal_reviews_author {
  id: string;
  name: string;
}

export interface SolutionFields_SolutionExternal_reviews_problem {
  id: string;
}

export interface SolutionFields_SolutionExternal_reviews_project {
  id: string;
}

export interface SolutionFields_SolutionExternal_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: SolutionFields_SolutionExternal_reviews_author;
  problem: SolutionFields_SolutionExternal_reviews_problem;
  project: SolutionFields_SolutionExternal_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface SolutionFields_SolutionExternal {
  id: string;
  source: any;
  body: string;
  htmlUrl: string;
  authorName: string;
  createdAt: any;
  updatedAt: any;
  reactions: SolutionFields_SolutionExternal_reactions;
  parent: SolutionFields_SolutionExternal_parent | null;
  reviews: SolutionFields_SolutionExternal_reviews[];
}

export interface SolutionFields_SolutionInternal_author {
  id: string;
  name: string;
}

export interface SolutionFields_SolutionInternal_reviews_author {
  id: string;
  name: string;
}

export interface SolutionFields_SolutionInternal_reviews_problem {
  id: string;
}

export interface SolutionFields_SolutionInternal_reviews_project {
  id: string;
}

export interface SolutionFields_SolutionInternal_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: SolutionFields_SolutionInternal_reviews_author;
  problem: SolutionFields_SolutionInternal_reviews_problem;
  project: SolutionFields_SolutionInternal_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface SolutionFields_SolutionInternal {
  id: string;
  source: any;
  body: string;
  author: SolutionFields_SolutionInternal_author;
  createdAt: any;
  updatedAt: any;
  reviews: SolutionFields_SolutionInternal_reviews[];
}

export type SolutionFields = SolutionFields_SolutionExternal | SolutionFields_SolutionInternal;

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SolutionExternalFields
// ====================================================

export interface SolutionExternalFields_reactions {
  totalCount: number;
  thumbsUp: number;
  thumbsDown: number;
  laugh: number;
  hooray: number;
  confused: number;
  heart: number;
}

export interface SolutionExternalFields_parent {
  id: string;
  source: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  htmlUrl: string;
  body: string;
}

export interface SolutionExternalFields_reviews_author {
  id: string;
  name: string;
}

export interface SolutionExternalFields_reviews_problem {
  id: string;
}

export interface SolutionExternalFields_reviews_project {
  id: string;
}

export interface SolutionExternalFields_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: SolutionExternalFields_reviews_author;
  problem: SolutionExternalFields_reviews_problem;
  project: SolutionExternalFields_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface SolutionExternalFields {
  id: string;
  source: any;
  body: string;
  htmlUrl: string;
  authorName: string;
  createdAt: any;
  updatedAt: any;
  reactions: SolutionExternalFields_reactions;
  parent: SolutionExternalFields_parent | null;
  reviews: SolutionExternalFields_reviews[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SolutionInternalFields
// ====================================================

export interface SolutionInternalFields_author {
  id: string;
  name: string;
}

export interface SolutionInternalFields_reviews_author {
  id: string;
  name: string;
}

export interface SolutionInternalFields_reviews_problem {
  id: string;
}

export interface SolutionInternalFields_reviews_project {
  id: string;
}

export interface SolutionInternalFields_reviews {
  id: string;
  status: ReviewStatus;
  solutionId: string;
  solutionSource: any;
  author: SolutionInternalFields_reviews_author;
  problem: SolutionInternalFields_reviews_problem;
  project: SolutionInternalFields_reviews_project;
  createdAt: string;
  updatedAt: string;
}

export interface SolutionInternalFields {
  id: string;
  source: any;
  body: string;
  author: SolutionInternalFields_author;
  createdAt: any;
  updatedAt: any;
  reviews: SolutionInternalFields_reviews[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SolutionParentFields
// ====================================================

export interface SolutionParentFields {
  id: string;
  source: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  htmlUrl: string;
  body: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StackTraceFields
// ====================================================

export interface StackTraceFields_frames_package {
  name: string | null;
  version: string | null;
  filePath: string | null;
}

export interface StackTraceFields_frames {
  filePath: string | null;
  fileName: string | null;
  callee: string | null;
  column: number | null;
  line: number | null;
  package: StackTraceFields_frames_package | null;
}

export interface StackTraceFields {
  kind: StackTraceKind;
  message: string;
  frames: StackTraceFields_frames[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserFields
// ====================================================

export interface UserFields {
  id: string;
  name: string;
  email: string;
  uploadToken: string;
  createdAt: string;
  updatedAt: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DependencyType {
  Composer = "Composer",
  Go = "Go",
  Maven = "Maven",
  NuGet = "NuGet",
  RubyGems = "RubyGems",
  npm = "npm",
  pip = "pip",
}

export enum ReviewStatus {
  FullFix = "FullFix",
  Irrelevant = "Irrelevant",
  NotAFix = "NotAFix",
  PartialFix = "PartialFix",
}

export enum StackTraceKind {
  DockerCompose = "DockerCompose",
  DockerDaemon = "DockerDaemon",
  Dockerfile = "Dockerfile",
  Generic = "Generic",
  Java = "Java",
  Node = "Node",
  Python = "Python",
  Ruby = "Ruby",
  TypeScriptCompiler = "TypeScriptCompiler",
  Webpack = "Webpack",
}

export interface BetweenDates {
  start: any;
  end: any;
}

export interface Dependency {
  type: DependencyType;
  name: string;
  version: string;
  issueTrackers: string[];
}

export interface ProblemsFilter {
  first?: number | null;
  message?: string | null;
  kinds?: StackTraceKind[] | null;
  between?: BetweenDates | null;
  projectId?: string | null;
  userIds?: string[] | null;
  projectIds?: string[] | null;
  isSolved?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
