import * as React from "react";
import { Container } from "reactstrap";

import { PrivateRoute } from "../Auth/PrivateRoute";
import { NavBar } from "../components/NavBar";
import Search from "./Search";
import Analytics from "./Analytics";
import Account from "./Account";
import Welcome from "./Welcome";
import Projects from "./Projects";
import Setup from "./Setup";
import UserProblems from "./UserProblems";

export default function Routes() {
  return (
    <div>
      <NavBar />
      <Container fluid={true} className="">
        <PrivateRoute exact path="/" component={Welcome} />
        <PrivateRoute path="/search" component={Search} />
        <PrivateRoute path="/problems" component={UserProblems} />
        <PrivateRoute path="/analytics" component={Analytics} />
        <PrivateRoute path="/projects" component={Projects} />
        <PrivateRoute path="/account" component={Account} />
        <PrivateRoute path="/setup" component={Setup} />
      </Container>
    </div>
  );
}
