export function parseGraphQLDate(dateStr: string): Date {
  const isDateInMs = parseInt(dateStr).toString() === dateStr;
  if (isDateInMs) {
    const val = new Date(+dateStr);
    // console.log("parseGraphQLDate", dateStr, val);
    return val;
  } else {
    const val = new Date(dateStr);
    // console.log("parseGraphQLDate", dateStr, val);
    return val;
  }
}
