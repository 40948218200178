import * as React from "react";
import { Alert } from "reactstrap";
import { Query, QueryResult } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import * as viewerQuery from "../graphql/queries/viewer.graphql";
import { ViewerQuery } from "../graphql";
import { LoadingSpinner } from "../components/LoadingSpinner";
import History from "./History";

export default function UserProblems() {
  return (
    <div>
      <Query query={viewerQuery} variables={{}}>
        {(queryResult: QueryResult<ViewerQuery>) => {
          const { loading, error, data } = queryResult;
          if (loading) {
            return <LoadingSpinner />;
          }
          if (error || !data) {
            console.error(error);
            return (
              <Alert color="danger">
                <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
                {error && error.message}
              </Alert>
            );
          }
          const { viewer } = data;
          if (!viewer) {
            console.log("No viewer");
            return <LoadingSpinner />;
          }
          const { user } = viewer;
          if (!user) {
            console.log("No user");
            return <LoadingSpinner />;
          }
          return <History userId={user.id} />;
        }}
      </Query>
    </div>
  );
}
