import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import * as _ from "lodash";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemText,
  ListGroupItemHeading,
  Button,
} from "reactstrap";
import * as ReactTooltip from "react-tooltip";

import {
  ProblemQuery_problem_stackTraces,
} from "../../../../graphql";
import * as stackWarningIcon from "../../../../images/stack-trace/stack-warning.svg";
import { StackTraceIcon } from "../../../../components/StackTraceIcon";

import { StackFrame } from "./StackFrame";

export class StackTrace extends React.Component<
  StackTraceProps,
  StackTraceState
> {
  state = {
    isOpen: false,
  };

  private collapsedFramesCount: number = 2;

  public componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  public render() {
    const { stackTrace, duplicates } = this.props;
    return (
      <ListGroupItem className="stack-trace">
        <ListGroupItemHeading tag="div">
          <img className="trace-icon" src={stackWarningIcon} />{" "}
          {stackTrace.message}{" "}
          {!!duplicates && (
            <>
              <FontAwesomeIcon icon={faClone} data-tip={`Found ${duplicates} more duplicate${duplicates === 1? '' : 's'}`} />
            </>
          )}
          <div className="pull-right">
            <StackTraceIcon kind={stackTrace.kind} className="kind-icon" />
          </div>
        </ListGroupItemHeading>
        <ListGroupItemText tag="div">
          <ListGroup flush>
            {this.renderFrames()}
            {!this.state.isOpen && this.hasMoreFrames ? (
              <div
                className=""
                title={`Show ${this.hiddenFramesCount} more stack frames`}
              >
                <Button color="link" onClick={this.toggle}>
                  <FontAwesomeIcon icon={faEllipsisH} /> Show{" "}
                  {this.hiddenFramesCount} more stack frame{this
                    .hiddenFramesCount === 1
                    ? ""
                    : "s"}
                </Button>
              </div>
            ) : (
              <div />
            )}
          </ListGroup>
        </ListGroupItemText>
      </ListGroupItem>
    );
  }

  private renderFrames() {
    const frames = this.state.isOpen ? this.frames : this.collapsedFrames;
    return frames.map((frame, index) => {
      return <StackFrame key={index} frame={frame} />;
    });
  }

  private get collapsedFrames() {
    return this.frames.slice(0, this.collapsedFramesCount);
  }

  private get frames() {
    return this.props.stackTrace.frames;
  }

  private get hasMoreFrames() {
    return this.hiddenFramesCount > 0;
  }

  private get hiddenFramesCount(): number {
    return this.frames.length - this.collapsedFramesCount;
  }

  private toggle = () =>
    this.setState(prevState => ({ ...prevState, isOpen: !prevState.isOpen }));
}

interface StackTraceProps {
  stackTrace: ProblemQuery_problem_stackTraces;
  duplicates: number;
}

export interface StackTraceState {
  isOpen: boolean;
}
