import * as React from "react";
import * as _ from "lodash";
import { ListGroupItem } from "reactstrap";

import {
  ProblemQuery_problem_stackTraces_frames,
  ProblemQuery_problem_stackTraces_frames_package,
} from "../../../../graphql";
import * as stackLocationIcon from "../../../../images/stack-trace/stack-location.svg";
import { FrameCode } from "./FrameCode";
import { FrameDocs } from "./FrameDocs";

export function StackFrame({
  frame,
}: {
  frame: ProblemQuery_problem_stackTraces_frames;
}) {
  const {
    filePath,
    fileName,
    callee,
    line,
    column,
    package: framePackage,
  } = frame;
  return (
    <ListGroupItem className="stack-frame">
      <div>
        <img src={stackLocationIcon} className="frame-icon" />{" "}
        {callee ? (
          <span>
            at <span className="callee">{callee}</span>
          </span>
        ) : (
          ""
        )}{" "}
        {framePackage ? (
          <span>
            in{" "}
            <FilePath
              framePackage={framePackage}
              filePath={filePath || fileName}
            />
          </span>
        ) : fileName ? (
          <span>
            in{" "}
            <FilePath
              framePackage={framePackage}
              filePath={filePath || fileName}
            />
          </span>
        ) : (
          ""
        )}
        {line ? (
          <span>
            {" "}
            - line <span className="line">{line}</span>
            {column ? (
              <span>
                , column <span className="column">{column}</span>
              </span>
            ) : (
              ""
            )}
          </span>
        ) : (
          ""
        )}
      </div>
      <FrameCode frame={frame} />
      <FrameDocs frame={frame} />
    </ListGroupItem>
  );
}

function FilePath(props: {
  framePackage: ProblemQuery_problem_stackTraces_frames_package | null;
  filePath: string | null;
}) {
  const { framePackage, filePath } = props;
  if (framePackage) {
    return (
      <span className="frame-source">
        <span className="frame-package-name">
          <a href={npmUrlForPackage(framePackage)} target="_blank">
            {framePackage.name}
            {framePackage.version ? `@${framePackage.version}` : ""}
          </a>
        </span>
        <span className="frame-file-path">/{framePackage.filePath}</span>
      </span>
    );
  } else if (filePath) {
    return (
      <span className="frame-source">
        <span className="frame-file-path">{filePath}</span>
      </span>
    );
  }
  return null;
}

function npmUrlForPackage(
  framePackage: ProblemQuery_problem_stackTraces_frames_package
): string {
  return `https://www.npmjs.com/package/${framePackage.name}/v/${
    framePackage.version
  }`;
}
