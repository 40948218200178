import * as React from "react";
import { Alert } from "reactstrap";
import { compose, graphql, QueryResult } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import * as projectsQuery from "../graphql/queries/projects.graphql";
import { ProjectsQuery } from "../graphql";
import { LoadingSpinner } from "./LoadingSpinner";

export class SelectProjectInternal extends React.Component<
  SelectProjectProps,
  SelectProjectState
> {
  state = {
    name: "",
  };

  public render() {
    const { loading, error, data } = this.props;
    if (loading) {
      return (
        <LoadingSpinner />
      );
    }
    if (error || !data) {
      console.error(error);
      return (
        <Alert color="danger">
          <FontAwesomeIcon icon={faExclamationCircle} /> Error:{" "}
          {error && error.message}
        </Alert>
      );
    }
    const { viewer } = data;
    if (!viewer) {
      console.log('No viewer');
      return (
        <LoadingSpinner />
      );
    }
    const { user } = viewer;
    if (!user) {
      console.log('No user');
      return (
        <LoadingSpinner />
      );
    }
    const { projects } = user;
    const { placeholder = "Select project" } = this.props;
    return (
      <div>
        <select
          className="form-control"
          onChange={this.onChangeName}
          value={this.props.projectId}
          disabled={this.props.readOnly}
        >
          <option value="">{placeholder}</option>
          {projects.map(project => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </select>
      </div>
    );
  }

  private onChangeName = (event: any) => {
    const { value } = event.target;
    return this.props.onChange && this.props.onChange(value);
  };
}

type SelectProjectInternalProps = {
  placeholder?: string;
  projectId?: string;
} & (
  {
    readOnly: true;
    onChange?: undefined;
  } | {
    readOnly?: false;
    onChange(projectId: string): void;
  }
);

type SelectProjectProps = SelectProjectInternalProps & QueryResult<ProjectsQuery, {}>;

interface SelectProjectState {}

export const SelectProject: React.SFC<SelectProjectInternalProps> = compose(graphql(projectsQuery))(
  SelectProjectInternal
);
