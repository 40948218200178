import * as React from "react";
import { Alert, Card } from "reactstrap";
import { parseDiff, Diff as DiffView } from "react-diff-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCode,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import CardHeader from "reactstrap/lib/CardHeader";

export class Diff extends React.PureComponent<DiffProps> {
  private requiredPrefix = `diff --git a/Diff b/Diff
index before..after
@@ -0,0 +0,0
`;

  public render() {
    try {
      console.log("diff", this.props);
      const parsedDiff = this.parseDiff(this.props.children);
      console.log("parsedDiff", parsedDiff);
      return (
        <React.Fragment>
          {parsedDiff.map(({ hunks, newPath }: any, i: number) => {
            return (
              <Card key={newPath}>
                <CardHeader>
                  <FontAwesomeIcon icon={faFileCode} /> {newPath}
                </CardHeader>
                {/* <CardBody>
                  <CardTitle />
                </CardBody> */}
                <div>
                  <DiffView key={i} hunks={hunks} viewType="split" />
                </div>
              </Card>
            );
          })}
          {/* <CardFooter>{parsedDiff.length} file(s) changed</CardFooter> */}
        </React.Fragment>
      );
    } catch (error) {
      console.error(error);
      return (
        <Alert color="danger">
          <FontAwesomeIcon icon={faExclamationCircle} /> Diff Error:{" "}
          {error && error.message}
        </Alert>
      );
    }
  }

  private parseDiff(diffText: string) {
    try {
      return parseDiff(diffText);
    } catch (error) {
      try {
        return parseDiff(`${this.requiredPrefix}\n${diffText}`);
      } catch (error2) {
        throw error;
      }
    }
  }
}

export interface DiffProps {
  children: string;
}
